import {
	Badge,
	Box,
	Button,
	Heading,
	Img,
	SimpleGrid,
	Stack,
	Text,
	useBreakpointValue,
	ding,
	AspectRatio,
	Center,
	List,
	ListItem,
	ListIcon,
	OrderedList,
	UnorderedList,
	Flex,
	Icon,
	Container,
	Grid,
	GridItem,
} from "@chakra-ui/react";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import { AiOutlinePlayCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import HeroHome from "../../Components/AppComponents/HeroHome";
import YouTube from "react-youtube";
import Swal from "sweetalert2";
import AppBarSideNavigation from '../../Components/AppBars/AppBarSideNavigation';
import getYouTubeID from 'get-youtube-id';

const TrainingOverviewPage = () => {
	const params = useParams();
	const { id, title } = params;
	let navigate = useNavigate();
	const [preview, setPreview] = useState({});
	const { state } = useLocation();
	const { training } = state; // Read values passed on state


	 const opts = {
			height: '390',
			width: '640',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 1,
				rel : 0,
				mute: 1
				// mute : 1
			},
		};
 

	const renderPlayList = () => {
		if (training && training.videoList?.length > 0) { 
				return training?.videoList?.map((trainingVideo ,index) => (
					<ListItem 
						py="4" 
						fontWeight="bold"
						key={index}
						fontSize={{sm:'sm', md:'md'}} 
						px="0.8rem"
						borderColor={trainingVideo.id == preview.id ? 'gray.50' : ''}
						borderWidth={trainingVideo.id == preview.id ? '0.5px' : '0px'}
						_hover={{ bg: "gray.200", color:'red' }}
						cursor="pointer"
						color='blackAlpha.700'
						onClick={() => handlePreview(trainingVideo, index)}
					>
							<Text>{trainingVideo.title}</Text>
					</ListItem>
				))
		}
	}

	const handlePreview = (trainingVideo, index) => {
		// console.log("trainingVideo: ", trainingVideo);
		setPreview(trainingVideo);
	};

	const handleEnd = async () => {
		const find = (video) => {
			return video.url == preview.url;
		};

		const nextIndex = preview.url !== undefined ? training?.videolist?.findIndex(find) + 1 : 1;
		// console.log("video index preview: ", videos.findIndex(find) + 1);

		Swal.fire({
			title: "Video watched!",
			text: `Kamu telah menyelesaikan ${
				preview.url !== undefined ? preview.title : training?.videoList[0]?.title
			}`,
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: "Video Selanjutnya ",
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				setPreview(training?.videoList[nextIndex]);
			} else if (result.isDenied) {
				Swal.fire("Changes are not saved", "", "info");
			}
		});
	};

	return (
		<>
			{/* <AppBarHeaderEmployee zIndex={2} /> */}
			{/* <Stack w="full" bg="gray.100">
				<Box display="flex" justifyContent="center" mt={20}>
					<Flex
						color="white"
						justify="center"
						maxW="80%"
						bg="white"
						padding={20}
					>
						<Box flex={5} p={3}>
						<Heading color="gray.500" my={3}>
							{training.videoList[0].title}
						</Heading>
						<YouTube
								videoId={
									preview.id !== undefined
										? preview.id
										: training.videoList[0].id
								} // defaults -> ''
								id={"zKDtDUM5Z6w"} // defaults -> ''
								title={"Javascript Basics"} // defaults -> ''
								onPlay={() => console.log("played!!!!")} // defaults -> noop
								onPause={() => console.log("paused!!!!")} // defaults -> noop
								onEnd={handleEnd} // defaults -> noop
							/>
						</Box>

						<Center flex={3}>
							<Box color="gray.500" maxH="100vh" overflow="scroll">
								<Heading my={5}>{params.id}</Heading>
								<List spacing={3}>
									{training.videoList
										? training.videoList.map((trainingVideo, i) => (
												<ListItem
													p={2}
													key={i}
													_hover={{ bg: "gray.200" }}
													cursor="pointer"
													onClick={() => handlePreview(trainingVideo, i)}
												>
													<ListIcon as={MdCheckCircle} color="green.500" />
													{trainingVideo.title}
													{trainingVideo.id == preview.id ? (
														<Icon
															as={AiOutlinePlayCircle}
															color="green.500"
															mx={4}
														/>
													) : null}
												</ListItem>
											))
										: null}
								</List>
							</Box>
						</Center>
					</Flex>
				</Box>
			</Stack> */}


				<AppBarSideNavigation>
					<Center>
					<Box  alignItems='center' justifyContent="center" display='flex' w='full' 
					// h='100vh' 
					>
					<Container maxW={'8xl'}>
							<Grid
								templateColumns={{
									base:'repeat(1, 1fr)',
									md:'repeat(6, 1fr)'
								}}
								gap={4}
							>
									<GridItem 
											colSpan={2}  
											borderRightWidth={{
												sm:'0',
												md:'0.5px'
											}}
											borderColor="blackAlpha.700"
									>
											<Box px="5">
													<Heading as='h4' size='xl' color="#37373C" mb="5" fontWeight={600}>
															{training.name}
													</Heading>
													<OrderedList color='blackAlpha.800'>
															{renderPlayList()}
													</OrderedList>
											</Box>
									</GridItem>
									<GridItem colSpan={4} px="3">
											{/* <Box width="100%" bg='teal'> */}
													<YouTube
														videoId={
															preview.url !== undefined
																? getYouTubeID(preview.url)
																: getYouTubeID(training.videoList[0].url)
														} // defaults -> ''
														id={"zKDtDUM5Z6w"} // defaults -> ''
														title={"Javascript Basics"} // defaults -> ''
														onPlay={() => console.log("played!!!!")} // defaults -> noop
														onPause={() => console.log("paused!!!!")} // defaults -> noop
														onEnd={handleEnd} // defaults -> noop
														opts={opts}
													/>
											{/* </Box> */}                         
													<Heading as='h2' size='md' mb="5"  color="#37373C" fontWeight={600}>
															{preview.url !== undefined
																? preview.title
																: training.videoList[0]?.title}
													</Heading>
									</GridItem>
						</Grid>
					</Container>
					</Box>
					</Center>
				</AppBarSideNavigation>



		</>
	);
};

export default TrainingOverviewPage;
