import React from 'react'
import AppBarEditTraining from '../../../Components/AppBars/AppBarEditTraining'
import AppBarSideNavigation from '../../../Components/AppBars/AppBarSideNavigation'

const EditTrainingPage = () => {
  return (
    <>
        <AppBarSideNavigation>
          <AppBarEditTraining/> 
        </AppBarSideNavigation>
    </>
  )
}

export default EditTrainingPage
