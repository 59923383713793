import { Box, Center, Heading, Text, Stack, VStack, Button, FormControl, FormLabel, Input, FormHelperText, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Image, Flex, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { wealth_dynamics } from '../Assessment/assessment'
import { db } from '../Config/firebase';
import { addDoc, collection, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import moment from 'moment';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';


const WealthDynamicTest = () => {
    const [data, setData] = useState({});
    const [answers, setAnswers] = useState({});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEligible, setIsEligible] = useState(false);
    const [password, setPassword] = useState("");
    const [assessmentDetails, setAssessmentDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const toast = useToast()

    const navigate = useNavigate();

    const getAssessmentDetails = async () => {
        const docRef = doc(db, "assessment", "Wc0w2l2J7nqszd6qDhV0");

        try {
            const docSnap = await getDoc(docRef);
            // console.log(docSnap.data(), "docsnap.data()");
            setAssessmentDetails(docSnap?.data())
        } catch (error) {
            console.log(error.message, `error in getting doc`);
        };
    };

    const handleChange = (questionId, answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer,
        }));
    };

    // const array = [
    //     { dynamo: 12, blaze: 16, tempo: 24, steel: 48 },
    //     { dynamo: 68, blaze: 4, tempo: 12, steel: 24 },
    //     { dynamo: 68, blaze: 12, tempo: 12, steel: 64 },
    //     { dynamo: 8, blaze: 4, tempo: 24, steel: 40 },
    //     { dynamo: 16, blaze: 8, tempo: 36, steel: 0 },
    //     { dynamo: 0, blaze: 12, tempo: 28, steel: 36 },
    //     { dynamo: 16, blaze: 40, tempo: 16, steel: 28 },
    //     { dynamo: 28, blaze: 20, tempo: 24, steel: 28 },
    //     { dynamo: 16, blaze: 24, tempo: 40, steel: 20 },
    //     { dynamo: 16, blaze: 28, tempo: 24, steel: 32 },
    //     { dynamo: 8, blaze: 48, tempo: 44, steel: 0 },
    //     { dynamo: 28, blaze: 20, tempo: 44, steel: 8 },
    //     { dynamo: 28, blaze: 8, tempo: 24, steel: 40 },
    //     { dynamo: 16, blaze: 48, tempo: 28, steel: 8 },
    //     { dynamo: 8, blaze: 8, tempo: 32, steel: 52 },
    //     { dynamo: 28, blaze: 16, tempo: 16, steel: 40 },
    //     { dynamo: 32, blaze: 8, tempo: 32, steel: 28 },
    //     { dynamo: 48, blaze: 20, tempo: 20, steel: 12 },
    //     { dynamo: 24, blaze: 12, tempo: 36, steel: 28 },
    //     { dynamo: 20, blaze: 24, tempo: 28, steel: 28 },
    //     { dynamo: 40, blaze: 48, tempo: 12, steel: 0 },
    //     { dynamo: 16, blaze: 20, tempo: 32, steel: 32 },
    //     { dynamo: 16, blaze: 16, tempo: 36, steel: 32 },
    //     { dynamo: 20, blaze: 4, tempo: 48, steel: 28 },
    //     { dynamo: 36, blaze: 24, tempo: 28, steel: 12 },
    //     { dynamo: 16, blaze: 20, tempo: 28, steel: 36 },
    //     { dynamo: 16, blaze: 8, tempo: 24, steel: 52 },
    //     { dynamo: 16, blaze: 16, tempo: 32, steel: 36 },
    //     { dynamo: 20, blaze: 4, tempo: 52, steel: 24 },
    //     { dynamo: 40, blaze: 12, tempo: 24, steel: 24 },
    //     { dynamo: 36, blaze: 24, tempo: 24, steel: 16 },
    //     { dynamo: 16, blaze: 12, tempo: 36, steel: 36 },
    // ];

    const checkPassword = () => {
        setLoading(true);

        setTimeout(() => {
            if (assessmentDetails?.isLocked && password === assessmentDetails?.password) {
                setIsEligible(true);
                toast({
                    title: "Success",
                    description: "Please proceed with the assessment",
                    status: "success",
                    isClosable: true,
                    duration: 9000
                })
                setLoading(false)

            } else {
                setIsEligible(false);
                toast({
                    title: "Wrong Password",
                    description: "Please contact HR for assessment access",
                    status: "error",
                    isClosable: true,
                    duration: 9000
                })
                setLoading(false)
            };
        }, 1000)

    };


    // const handleTest = ( ) => {
    //     for (let totalScore of array){
    //           const arrayFromTotalScore = Object.entries(totalScore);
    //           const slicedArray = arrayFromTotalScore.slice(0, 3);
    //                      if (slicedArray[0][1] === slicedArray[1][1]) { //jika 2 terbesar pertama sama, Maka lihat yang ke3,
    //             // console.log('1 dan 2 sama, maka lihat nilai ketiga');

    //             if (slicedArray[1][1] === slicedArray[2][1]) {
    //                 // console.log("2 dan 3 sama, artinya 123 sama.");

    //             } else {
    //                 // console.log('1 dan 2 sama, 3 beda maka campuran dari 1 dan 2');

    //                 switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
    //                         case 'dynamo_blaze_steel':
    //                         case 'blaze_dynamo_steel':
    //                             console.log(totalScore, 'Star - Dynamo')
    //                             break;
    //                         case 'dynamo_blaze_tempo':
    //                         case 'blaze_dynamo_tempo':
    //                             console.log(totalScore, 'Star - Blaze')
    //                             break;
    //                         case 'blaze_tempo_dynamo':
    //                         case 'tempo_blaze_dynamo':
    //                             console.log(totalScore, 'Deal Maker - Blaze')
    //                             break;
    //                         case 'blaze_tempo_steel':
    //                         case 'tempo_blaze_steel':
    //                             console.log(totalScore, 'Deal Maker - Tempo')
    //                             break;
    //                         case 'tempo_steel_blaze':
    //                         case 'steel_tempo_blaze':
    //                             console.log(totalScore, 'Accumulator - Tempo')
    //                             break;
    //                         case 'tempo_steel_dynamo':
    //                         case 'steel_tempo_dynamo':
    //                             console.log(totalScore, 'Accumulator - Steel')
    //                             break;
    //                         case 'dynamo_steel_tempo':
    //                         case 'steel_dynamo_tempo':
    //                             console.log(totalScore, 'Mechanic - Steel')
    //                             break;
    //                         case 'dynamo_steel_blaze':
    //                         case 'steel_dynamo_blaze':
    //                             console.log(totalScore, 'Mechanic - Dynamo')
    //                             break;
    //                         default:
    //                             console.log(totalScore, `nyebrang boss (${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[1]})`)
    //                             break;
    //                     };

    //                 };
    //         } else if (slicedArray[0][1] !== slicedArray[1][1]) { 

    //                     if ((slicedArray[0][1] - slicedArray[1][1]) >= 10) {
    //                         //TULEN KARENA SELISIH TERBESAR 1 dan 2 lebih dari 10
    //                         switch (slicedArray[0][0]) {
    //                             case 'dynamo':
    //                                 console.log(totalScore, 'creator')
    //                                 break;
    //                             case 'blaze':
    //                                 console.log(totalScore, 'supporter')
    //                                 break;
    //                             case 'tempo':
    //                                 console.log(totalScore, 'trader')
    //                                 break;
    //                             case 'steel':
    //                                 console.log(totalScore, 'lord')
    //                                 break;
    //                             default:
    //                                 break;
    //                         };
    //                     } else {
    //                         //CAMPURAN KARENA SELISIHNYA KURANG DARI 10
    //                         switch (`${slicedArray[0][0]}_${slicedArray[1][0]}`) {
    //                             case 'dynamo_blaze':
    //                                 console.log(totalScore, 'Star - Dynamo');
    //                                 break;
    //                             case 'blaze_dynamo':
    //                                 console.log(totalScore, 'Star - Blaze');
    //                                 break;
    //                             case 'blaze-tempo':
    //                                 console.log(totalScore, 'Deal Maker - Blaze');
    //                                 break;
    //                             case 'tempo_blaze':
    //                                 console.log(totalScore, 'Deal Maker - Tempo');
    //                                 break;
    //                             case 'tempo_steel':
    //                                 console.log(totalScore, 'Accumulator - Tempo');
    //                                 break;
    //                             case 'steel_tempo':
    //                                 console.log(totalScore, 'Accumulator - Steel');
    //                                 break;
    //                             case 'steel_dynamo':
    //                                 console.log(totalScore, 'Mechanic - Steel');
    //                                 break;
    //                             case 'dynamo_steel':
    //                                 console.log(totalScore, 'Mechanic - Dynamo');
    //                                 break;
    //                             default:
    //                                 console.log(totalScore, `nyebrang boss (${slicedArray[0][0]}_${slicedArray[1][0]})`);
    //                                 break;
    //                         };
    //             };
    //         };

    //     }
    // }


    const handleSubmit = async (event) => {
        if (name || name.length > 0 || phone || phone.length > 0) {
            // event.preventDefault();
            // console.log(answers);
            //============//============//============//============//============//============//============//============//
            //INITIALIZE SCORES TO ZERO
            let houseCount = { dynamo: 0, blaze: 0, tempo: 0, steel: 0 };


            //COUNT HOW MANY ANSWERS EACH HOUSE
            for (let key in answers) {
                const house = answers[key].house;
                houseCount[house]++;
            };
            // console.log("jumlah jawaban :", houseCount);

            ////ANSWERS HAS TO BE MULTIPLIED BY 4 TO GET 100 IN TOTAL
            let totalScore = houseCount;
            // console.log(totalScore)

            for (let key in totalScore) {
                totalScore[key] *= 4;
            }
            // console.log('totalScore :', totalScore);
            //============//============//============//============//============//============//============//============//



            //SORT THE THE SCORES
            const arrayFromTotalScore = Object.entries(totalScore); //expected output : Array of arrays [[tempo, 36], [blaze, 32], [steel, 32], [dynamo, 32]] 
            arrayFromTotalScore.sort((a, b) => b[1] - a[1]);

            const slicedArray = arrayFromTotalScore.slice(0, 3); //expected output : Array of arrays [[tempo, 36], [blaze, 32], [steel, 32]] 



            //INITIALIZE RESULT
            let result = {
                name: name,
                phone: phone,
                answers: answers,
                totalScore: totalScore,
                profile: '',
                house: '',
                timestamp: serverTimestamp(),
                date: moment().format(),
                type: 'wealth_dynamics'
            };

            if (slicedArray[0][1] === slicedArray[1][1]) {
                //jika 2 terbesar pertama sama, Maka lihat yang ke3,
                // console.log('1 dan 2 sama, maka lihat nilai ketiga');

                if (slicedArray[1][1] === slicedArray[2][1]) {
                    // kedua dan ketiga sama
                    // console.log("2 dan 3 sama, artinya 123 sama.");
                } else {
                    console.log('1 dan 2 sama, 3 beda maka campuran dari 1 dan 2');

                    switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
                        case 'dynamo_blaze_steel':
                        case 'blaze_dynamo_steel':
                            // alert('Star - Dynamo')
                            result.profile = 'star';
                            result.house = 'dynamo';
                            break;
                        case 'dynamo_blaze_tempo':
                        case 'blaze_dynamo_tempo':
                            // alert('Star - Blaze')
                            result.profile = 'star';
                            result.house = 'blaze';
                            break;
                        case 'blaze_tempo_dynamo':
                        case 'tempo_blaze_dynamo':
                            // alert('Deal Maker - Blaze')
                            result.profile = 'deal maker';
                            result.house = 'blaze';
                            break;
                        case 'blaze_tempo_steel':
                        case 'tempo_blaze_steel':
                            // alert('Deal Maker - Tempo')
                            result.profile = 'deal maker';
                            result.house = 'tempo';
                            break;
                        case 'tempo_steel_blaze':
                        case 'steel_tempo_blaze':
                            // alert('Accumulator - Tempo')
                            result.profile = 'accumulator';
                            result.house = 'tempo';
                            break;
                        case 'tempo_steel_dynamo':
                        case 'steel_tempo_dynamo':
                            // alert('Accumulator - Steel')
                            result.profile = 'accumulator';
                            result.house = 'steel';
                            break;
                        case 'dynamo_steel_tempo':
                        case 'steel_dynamo_tempo':
                            // alert('Mechanic - Steel')
                            result.profile = 'mechanic';
                            result.house = 'steel';
                            break;
                        case 'dynamo_steel_blaze':
                        case 'steel_dynamo_blaze':
                            // alert('Mechanic - Dynamo')
                            result.profile = 'mechanic';
                            result.house = 'dynamo';
                            break;
                        default:
                            // alert(`nyebrang boss (${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[1]})`)
                            break;
                    };

                };
            } else if (slicedArray[0][1] !== slicedArray[1][1]) {
                // console.log('1 dan 2 tidak sama');

                if ((slicedArray[0][1] - slicedArray[1][1]) >= 10) {
                    console.log('selisih lebih besar dari 10')
                    //TULEN KARENA SELISIH TERBESAR 1 dan 2 lebih dari 10
                    switch (slicedArray[0][0]) {
                        case 'dynamo':
                            // alert('creator')
                            result.profile = 'creator';
                            result.house = 'dynamo';
                            break;
                        case 'blaze':
                            // alert('supporter')
                            result.profile = 'supporter';
                            result.house = 'blaze';
                            break;
                        case 'tempo':
                            // alert('trader')
                            result.profile = 'trader';
                            result.house = 'tempo';
                            break;
                        case 'steel':
                            // alert('lord')
                            result.profile = 'lord';
                            result.house = 'steel';
                            break;
                        default:
                            break;
                    };
                } else if ((slicedArray[0][1] - slicedArray[1][1]) < 10) {
                    // console.log('selisih kurang dari 10')
                    // console.log(`${slicedArray[0][0]}_${slicedArray[1][0]}`)
                    //CAMPURAN KARENA SELISIHNYA KURANG DARI 10

                    if (slicedArray[1][1] === slicedArray[2][1]) {
                        //////// selisih kurang dari 10 dan 2 3 nilainya sama.
                        if (`${slicedArray[1][0]}_${slicedArray[2][0]}` === 'steel_blaze' || `${slicedArray[1][0]}_${slicedArray[2][0]}` === `blaze_steel` || `${slicedArray[1][0]}_${slicedArray[2][0]}` === `dynamo_tempo` || `${slicedArray[1][0]}_${slicedArray[2][0]}` === `tempo_dynamo`) {
                            // console.log('selisih kurang dari 10 dan 2 3 nilainya sama, serta 2 3 itu bersebrangan : ', `${slicedArray[1][0]}_${slicedArray[2][0]}`)

                            switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
                                case 'dynamo_steel_blaze':
                                case 'dynamo_blaze_steel':
                                    result.profile = 'creator';
                                    result.house = 'dynamo';
                                    break;
                                case 'blaze_dynamo_tempo':
                                case 'blaze_tempo_dynamo':
                                    result.profile = 'supporter';
                                    result.house = 'blaze';
                                    break;
                                case 'tempo_blaze_steel':
                                case 'tempo_steel_blaze':
                                    result.profile = 'trader';
                                    result.house = 'tempo';
                                    break;
                                case 'steel_dynamo_tempo':
                                case 'steel_tempo_dynamo':
                                    result.profile = 'lord';
                                    result.house = 'steel';
                                    break;
                                default:
                                    break;
                            };
                        } else {
                            // console.log('selisih kurang dari 10 dan 2 3 nilainya sama, serta 2 3 itu TIDAK bersebrangan : ', `${slicedArray[1][0]}_${slicedArray[2][0]}`);

                            switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
                                case 'steel_tempo_blaze':
                                case 'steel_blaze_tempo':
                                    result.profile = 'accumulator';
                                    result.house = 'steel';
                                    break;
                                case 'dynamo_steel_tempo':
                                case 'dynamo_tempo_steel':
                                    result.profile = 'mechanic';
                                    result.house = 'dynamo';
                                    break;
                                case 'blaze_dynamo_steel':
                                case 'blaze_steel_dynamo':
                                    result.profile = 'star';
                                    result.house = 'blaze';
                                    break;
                                case 'tempo_blaze_dynamo':
                                case 'tempo_dynamo_blaze':
                                    result.profile = 'deal maker';
                                    result.house = 'tempo';
                                    break;

                                default:
                                    break;
                            };
                        };
                    } else {
                        ////// selisih kurang dari 10 dan 2 3 nilainya TIDAK sama
                        switch (`${slicedArray[0][0]}_${slicedArray[1][0]}`) {
                            case 'dynamo_blaze':
                                // alert('Star - Dynamo');
                                result.profile = 'star'
                                result.house = 'dynamo';
                                break;
                            case 'blaze_dynamo':
                                // alert('Star - Blaze');
                                result.profile = 'star'
                                result.house = 'blaze';
                                break;
                            case 'blaze_tempo':
                                // alert('Deal Maker - Blaze');
                                result.profile = 'deal maker'
                                result.house = 'blaze';
                                break;
                            case 'tempo_blaze':
                                // alert('Deal Maker - Tempo');
                                result.profile = 'deal maker'
                                result.house = 'tempo';
                                break;
                            case 'tempo_steel':
                                // alert('Accumulator - Tempo');
                                result.profile = 'accumulator'
                                result.house = 'tempo';
                                break;
                            case 'steel_tempo':
                                // alert('Accumulator - Steel');
                                result.profile = 'accumulator'
                                result.house = 'steel';
                                break;
                            case 'steel_dynamo':
                                // alert('Mechanic - Steel');
                                result.profile = 'mechanic'
                                result.house = 'steel';
                                break;
                            case 'dynamo_steel':
                                // alert('Mechanic - Dynamo');
                                result.profile = 'mechanic'
                                result.house = 'dynamo';
                                break;
                            default:
                                // alert(`nyebrang boss (${slicedArray[0][0]}_${slicedArray[1][0]})`);
                                console.log('nyebrangggg', `${slicedArray[0][0]}_${slicedArray[1][0]}`)
                                break;
                        };
                    };
                };
            };

            //=====INITIAL COUNT : HOW MANY EACH HOUSE ANSWERS

            let count = {};
            for (let key in totalScore) {
                count[key] = totalScore[key] / 4
            };

            result['count'] = count;
            //=====INITIAL COUNT : HOW MANY EACH HOUSE ANSWERS ENDS


            //SAVE TO FIREBASE DB
            try {
                await addDoc(collection(db, 'assessment', 'Wc0w2l2J7nqszd6qDhV0', 'results'), result)
            } catch (error) {
                console.log(error.message)
            }
            onOpen();


            // console.log(result, 'this is the result');
            navigate({
                search: createSearchParams({
                    name: name,
                    profile: result.profile,
                    house: result.house,
                    phone: result.phone,
                    dynamo: result.totalScore.dynamo,
                    blaze: result.totalScore.blaze,
                    tempo: result.totalScore.tempo,
                    steel: result.totalScore.steel,
                })?.toString()
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: `Kamu belum mengisi nama / nomor telepon`,
                text: ``,
            });
        }
    };


    useEffect(() => {
        getAssessmentDetails();
    }, [])


    return (
        <>
            <Stack bg='gray.100' padding={10}>
                <Modal onClose={onClose} size='full' isOpen={isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Center>
                                <Image src='http://wdprofiletest.com/mobile/img/landing_logo.jpg' maxW='md' />
                            </Center>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack gap={100}>
                                <Text>According to your submission, your personality type according to Wealth Dynamics is:</Text>
                                <Box mt={20} display='flex' flexDirection='column' alignItems='center' gap={2}>
                                    <Heading size='xl' mt>{searchParams?.get('profile')?.toUpperCase() || ''}</Heading>
                                    <Heading size='md'>{searchParams?.get('house') || ''}</Heading>
                                </Box>
                                <Center flexDirection='column' gap={3}>
                                    <Text m={1}>Name : {searchParams?.get('name') || ''}</Text>
                                    <Text>Phone : {searchParams?.get('phone') || ''}</Text>
                                    <Text>Dynamo : {searchParams?.get('dynamo') || ''}</Text>
                                    <Text>Blaze : {searchParams?.get('blaze') || ''}</Text>
                                    <Text>Tempo : {searchParams?.get('tempo') || ''}</Text>
                                    <Text>Steel : {searchParams?.get('steel') || ''}</Text>
                                </Center>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button bg='red' color='white' onClick={() => {
                                onClose();
                                navigate('/');
                            }}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Center my={5}>
                    <VStack>
                        <Heading>Wealth Dynamics Assessment</Heading>
                        <Text fontSize={20} fontWeight={700}>
                            Silakan isi 25 pertanyaan di bawah ini yang paling sesuai sama kepribadianmu, tidak ada jawaban benar atau salah, dan tidak ada kepribadian yang baik atau buruk. Test ini tidak mempengaruhi hasil penilaian tes yang lain.
                        </Text>
                    </VStack>
                </Center>
                <Center mb={5} >
                    <Stack minW={{ base: '', md: '', lg: 'lg' }}>
                        <FormControl>
                            <FormLabel>Masukkan Nama Kamu:</FormLabel>
                            <Input bg='white' type='text' placeholder='Your name here' isRequired onChange={(e) => setName(e.target.value)} />
                            <FormHelperText>Pastikan untuk mengisi nama</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Masukkan Nomor HP:</FormLabel>
                            <Input bg='white' type='number' placeholder='0878*******' isRequired onChange={(e) => setPhone(e.target.value)} />
                            <FormHelperText>Pastikan untuk mengisi nomor hp</FormHelperText>
                        </FormControl>

                        {assessmentDetails?.isLocked && assessmentDetails?.password?.length > 0 ?
                            <FormControl>
                                <FormLabel>Password: </FormLabel>
                                <Flex gap={2}>
                                    <Input bg='white' type='text' placeholder='' isRequired onChange={(e) => setPassword(e.target.value)} />
                                    <Button
                                        colorScheme='green'
                                        onClick={checkPassword}
                                        isLoading={loading}
                                        loadingText='Checking..'
                                        isDisabled={loading}
                                    >
                                        Submit
                                    </Button>

                                </Flex>
                                <FormHelperText>Masukkan password untuk bisa mengisi WD Assessment</FormHelperText>
                            </FormControl> : null}
                    </Stack>
                </Center>


                {isEligible || !assessmentDetails?.isLocked ?

                    <>
                        <form>
                            {wealth_dynamics.map((question, i) => (
                                <Box padding={5} bg='white' margin={2} key={i} boxShadow="lg">
                                    <h2 style={{ fontWeight: 'bold' }}>{question.question}</h2>
                                    <Stack>
                                        {question.answers.map((answer, index) => (
                                            <label key={index} style={{ marginRight: '2em' }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${question.id}`}
                                                    value={answer}
                                                    style={{ marginRight: 10 }}
                                                    checked={answers[question.id] === answer}
                                                    onChange={() => handleChange(question.id, answer)}
                                                />
                                                {answer.answer}
                                            </label>
                                        ))}
                                    </Stack>
                                </Box>
                            ))}
                            {/* <Button bg='green' color='white' onClick={handleTest}>Submit Now</Button> */}
                        </form>
                        <Button type="submit" bg='green' onClick={handleSubmit} color='white'>Submit Now</Button>
                    </>
                    : <></>}

            </Stack>
        </>
    )
}

export default WealthDynamicTest
