import { Button } from "@chakra-ui/button";
import { Spinner } from "@chakra-ui/spinner";
import React from "react";

const AppButton = (props) => {
  const { title, bg, color, onClick, loading, event } = props;
  return (
    <Button
      bg={bg}
      color={color}
      _hover={{
        bg: "red.200",
      }}
      m={5}
      onClick={onClick}
      disabled={loading ? true : false}
    >
      {title} {loading ? <Spinner mx={5} /> : null}
    </Button>
  );
};

export default AppButton;
