import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { authFirebase, db } from "../Config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

const AppContext = createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);

  const [loading, setLoading] = context.loading;
  const [auth, setAuth] = context.auth;
  const [notif, setNotif] = context.notif;
  const [notifMessage, setNotifMessage] = context.notifMessage;
  const [notifTitle, setNotifTitle] = context.notifTitle;
  const [notifColor, setNotifColor] = context.notifColor;
  const [availableRoles, setAvailableRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState('');

  const getUser = () => {
    try {
      const user = authFirebase.currentUser;
      setAuth(user);
    } catch (error) {
      console.log(error);
    }
  };

  const notifShow = ({ title, message, color, duration }) => {
    setNotif(true);
    setNotifTitle(title);
    setNotifMessage(message);
    setNotifColor(color);

    setTimeout(
      () => {
        notifClose();
      },
      typeof duration == "undefined" ? 5000 : duration
    );
  };

  const notifClose = () => {
    setNotif(false);
  };

  const loadingShow = () => {
    setLoading(true);
  };

  const loadingClose = () => {
    setLoading(false);
  };

  const xx = localStorage.getItem("userData");
  const userData = xx && JSON.parse(xx);

  const companyId = localStorage.getItem("companyId");
  const x = localStorage.getItem("companyData");
  const companyData = x == undefined || x == null || !x ? null : JSON.parse(x);

  const getAvailableRoles = async () => {
    const q = query(
      collection(db, "roles"),
      // where("status", "!==", "not_suitable"),
      orderBy("timestamp", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({ data: doc.data(), id: doc.id });
      });
      console.log("roles in context ", arr);
      setAvailableRoles(arr);
    });
  };



  const getCompanies = async () => {
    const q = query(collection(db, "company"));
    let arr = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setCompanies(arr);
  };


  useEffect(() => {
    getUser();
    getAvailableRoles();
    getCompanies();
    return () => { };
  }, []);

  return {
    auth,
    availableRoles,
    companies,
    companyData,
    companyId,
    notif,
    notifShow,
    notifClose,
    notifMessage,
    notifTitle,
    notifColor,
    loading,
    loadingShow,
    loadingClose,
    selectedCompanies,
    setSelectedCompanies,
    userData
  };
};

export const AppProvider = ({ children }) => {
  const [auth, setAuth] = useState("");
  //notif state
  const [notif, setNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("success");
  const [notifTitle, setNotifTitle] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        auth: [auth, setAuth],
        notif: [notif, setNotif],
        notifTitle: [notifTitle, setNotifTitle],
        notifMessage: [notifMessage, setNotifMessage],
        notifColor: [notifColor, setNotifColor],
        loading: [loading, setLoading],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
