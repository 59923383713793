import { Box, Center } from "@chakra-ui/layout";
import React from "react";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import AppBarEditEmployeeProfile from "../../Components/AppBars/AppBarEditEmployeeProfile";
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import AppBarEditEmployerProfile from "../../Components/AppBars/AppBarEditEmployerProfile";

const EmployerProfileEditPage = () => {
  return (
    <>
      <Box w="full" bg="gray.200" 
      // minH="100vh"
      >
        {/* <AppBarHeaderNew /> */}
        <Box
          bg="white"
          mx={"10%"}
          //   mt={{ base: "5%", md: "5%", lg: "10%", xl: "10%" }}
        >
          <Center my={"5%"} py="10%">
            <AppBarEditEmployerProfile />
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default EmployerProfileEditPage;
