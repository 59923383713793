import React from "react";
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import AppBarProfile from "../../Components/AppBars/AppBarProfile";
import AppBarEditCompany from "../../Components/AppBars/AppBarEditCompany";

const CompanyProfilePage = () => {
  return (
    <>
      {/* <AppBarHeaderNew /> */}
      <AppBarEditCompany />
    </>
  );
};

export default CompanyProfilePage;
