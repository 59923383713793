import React from "react";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import AppBarHeaderMain from "../../Components/AppBars/AppBarHeaderMain";
import AppBarProfilePage from "../../Components/AppBars/AppBarProfile";

const EmployeeProfilePage = () => {
  return (
    <>
      {/* <AppBarHeaderEmployee /> */}
      <AppBarProfilePage />
    </>
  );
};

export default EmployeeProfilePage;
