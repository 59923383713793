  export const wealth_dynamics = [
    {

      id : 1,
      question : 'Which of the following words best describes you more then the others ? Kata kata yang paling menjelaskan kamu siapa.',
      answers : [
        {
          answer : 'creative / kreatif',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'fun loving / menyenangkan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'careful / hati-hati',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'detailed / detail',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 2,
      question : 'How would you like others to see you ? Bagaimana kamu ingin di lihat oleh orang lain?',
      answers : [
        {
          answer : 'Dynamic / dinamis',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'Friendly / ramah',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'Reliable / dapat diandalkan',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'Decisive / pasti',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 3,
      question : 'What do you enjoy most? Apa yang paling bisa kamu nikmati ?',
      answers : [
        {
          answer : 'Being creative / Kreatif',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'Being admired / Dikagumi',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'Having perspective / Memiliki perspektif',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'Being in control / Memegang kontrol',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 4,
      question : 'When starting a project, which do you enjoy most? Saat memulai project, apa yang kamu paling nikmati?',
      answers : [
        {
          answer : 'strategic planning / perencanaan strategis',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'team planning / perencanaan tim',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'system planning / perencanaan sistem',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'networking / berjejaring',
          house : 'blaze',
          house_index : 2
        },
      ]
    },
    {
      id : 5,
      question : 'How would others most likely describe you at party? Bagaimana orang lain melihat kamu dalam sebuah pesta?',
      answers : [
        {
          answer : 'sociable / ramah',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'confident / percaya diri',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'outrageous / reaksi yang kuat',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'reserved / tidak nyaman sharing kepada orang',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 6,
      question : 'When getting things done under pressure, which do you rely on LEAST? Pada saat menyelesaikan tugas dalam tekanan, apa yang paling SEDIKIT kamu andalkan?',
      answers : [
        {
          answer : 'your talents - bakat kamu',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'your hard work  - kerja keras',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'your efficiency - efisiensi kamu',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'your contacts - kontak kamu',
          house : 'blaze',
          house_index : 2
        },
      ]
    },
    {
      id : 7,
      question : 'Which of the following words describes you more then the others ? Kata kata yang paling menjelaskan kamu siapa.',
      answers : [
        {
          answer : 'indecisive  / tidak mudah memutuskan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'introverted / introvert',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'impatient / tidak sabar',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'unfocused / tidak fokus',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 8,
      question : 'How do you prefer making an important decision? Bagaimana cara kamu dalam membuat keputusan penting?',
      answers : [
        {
          answer : 'Consider all angles carefully / lihat semua sudut pandang dengan seksama',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'See how others have decided / lihat bagaimana orang lain memutuskan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'Go with your insticts / ikuti insting',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'Ask advice from friends / tanya teman untuk nasihat',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 9,
      question : 'How would you like others to NOT see you? Bagaimana kamu ingin orang lain TIDAK lihat darimu?',
      answers : [
        {
          answer : 'lonely / kesepian',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'weak / lemah',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'unreliable / tidak dapat di andalkan',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'boring / membosankan',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 10,
      question : 'Which project do you enjoy the most? Project apa yang paling kamu sukai?',
      answers : [
        {
          answer : 'fast growth startup - startup',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'multi-chain operation - korporasi',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'people based business - bisnis dengan orang',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'trading business - bisnis jual beli',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 11,
      question : 'When starting a project which do you enjoy LEAST? Saat memulai project kamu paling TIDAK enjoy di bagian apa?',
      answers : [
        {
          answer : 'system planning / perencanaan sistem',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'networking / berjejaring',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'strategic planning / perencanaan strategi',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'team planning /  perencanaan tim',
          house : 'dynamo',
          house_index : 1
        },
      ]
    },
    {
      id : 12,
      question : 'When in a team, what role do you most often play? Dalam team, kamu paling sering menjadi peran apa?',
      answers : [
        {
          answer : 'creative input / pemberi input kreatif',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'networker / orang bersosial',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'analyser / analisator',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'reliable worker / pekerja yang dapat diandalkan',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 13,
      question : 'What is your worst weakness? Kamu paling buruk dalam?',
      answers : [
        {
          answer : 'completing existing project / menyelesaikan proyek yang sudah ada',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'negotiating better rates / menegosiasikan harga yang lebih baik',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'building relationship / membangun relasi',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'creating new ideas / menciptakan ide baru',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 14,
      question : 'Which of the following is your greatest talent? Apa talenta terbaik kamu?',
      answers : [
        {
          answer : 'find ways to improve things / mencari cara untuk memperbaiki sesuatu',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'finding the right person / mencari orang yang tepat',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'following the right process / mengikuti proses yang tepat dan benar',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'getting the best price / mendapatkan harga terbaik',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 15,
      question : 'What causes you the most stress in team? Apa yang membuat kamu stress dalam sebuah tim?',
      answers : [
        {
          answer : 'lack of harmony / kurang keharmonisan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'lack of progress / kekurangan progres',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'lack of structure / kurang terstruktur',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'lack of exictement / kurang kesenangan',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 16,
      question : 'What do you enjoy least? Apa yang paling TIDAK bisa kamu nikmati?',
      answers : [
        {
          answer : 'uninvited guess / tamu yang tidak diundang',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'inflexible people / orang yang tidak fleksibel',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'structured iregularity / struktur yang tidak jelas',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'confusion and chaos / kebingungan dan kekacauan',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 17,
      question : 'What do you do best? Apa talenta yang terbaik kamu?',
      answers : [
        {
          answer : 'complete / menyelesaikan',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'negotiate / negosiasi',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'promote / mempromosikan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'create / menciptakan',
          house : 'dynamo',
          house_index : 1
        },
      ]
    },
    {
      id : 18,
      question : 'Which are you weakest at? Kamu paling lemah dalam hal?',
      answers : [
        {
          answer : 'analysing trends and oportunities / menganalisis tren dan kesempatan',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'creating system that improve things / menciptakan sistem untuk memperbaiki banyak hal',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'coming up with new ideas / menyodorkan ide baru',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'getting along with and connecting people / menjadi teman dan menjalin relasi dengan orang',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 19,
      question : 'What gives you the greatest sense of achievement?  Apa yang memberikan kamu rasa pencapaian yang tertinggi?',
      answers : [
        {
          answer : 'seeing your creation come alive / melihat ciptaanmu digunakan',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'meeting an incredible new contact / bertemu dengan orang baru yang luar biasa',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'finding a fantastic bargain / menemukan tawaran yang paling bagus',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'seeing your system like clockwork / melihat sistem buatanmu berjalan dengan sangat mulus',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 20,
      question : 'What annoys you the most about others? Apa yang membuat kamu terganggu terhadap orang lain?',
      answers : [
        {
          answer : 'lack of initiative / kurangnya inisiatif',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'not being friendly / tidak ramah',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'rule breakers / pelanggar aturan',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'not caring for others / tidak ada kepedulian terhadap yang lain',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 21,
      question : 'When at work how would others describe you the most? Saat kerja, bagaimana orang lain melihat kamu?',
      answers : [
        {
          answer : 'outgoing / ramah',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'innotvative / inovatif',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'structured / terstruktur',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'reliable / dapat diandalkan',
          house : 'tempo',
          house_index : 3
        },
      ]
    },
    {
      id : 22,
      question : 'When at work how would others describe you the LEAST? Saat kerja, apa yang paling TIDAK orang lain lihat dari kamu?',
      answers : [
        {
          answer : 'reliable / dapat diandalkan',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'structured / terstruktur',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'innotvative / inovatif',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'outgoing / ramah',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 23,
      question : 'What are you strongest at? Kemampuan terbaikmu?',
      answers : [
        {
          answer : 'coming up with new ideas / menyodorkan ide baru',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer : 'getting along with and connecting people / berteman dan berelasi dengan orang-orang',
          house : 'blaze',
          house_index : 2
        },
        {
          answer : 'analysing trends and oportunities / menganalisis tren dan kesempatan',
          house : 'tempo',
          house_index : 3
        },
        {
          answer : 'creating system that improve / menciptakan sistem yang lebih baik',
          house : 'steel',
          house_index : 4
        },
      ]
    },
    {
      id : 24,
      question : 'When under pressure, which do you rely on most to get going? Saat dalam tekanan, hal apa yang membantu kamu menyelesaikan masalah?',
      answers : [
        {
          answer :'your talents / talenta kamu',
          house : 'tempo',
          house_index : 3
        },
        {
          answer :'your hard work / kerja kerasmu',
          house : 'steel',
          house_index : 4
        },     
         {
          answer : 'your efficiency / efisiensi kamu',
          house : 'dynamo',
          house_index : 1
        },
        {
          answer :'your contacts / kontak kamu',
          house : 'blaze',
          house_index : 2
        },
      ]
    },
    {
      id : 25,
      question : 'When finishing a project, which do you enjoy most? Saat menyelesaikan sebuah project, apa yang paling kamu nikmati?',
      answers : [
        {
          answer :'celebrating / merayakan',
          house : 'blaze',
          house_index : 2
        },
        {
          answer :'congratulating team / memberi selamat kepada tim',
          house : 'tempo',
          house_index : 3
        },
        {
          answer :'completing paperwork / menyelesaikan laporan proyek',
          house : 'steel',
          house_index : 4
        },
        {
          answer : 'starting next project / memulai proyek baru',
          house : 'dynamo',
          house_index : 1
        },
      ]
    },
  ]