import React from 'react'
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation';
import AppBarTraining from '../Components/AppBars/AppBarTraining';

const TrainingListPage = () => {
  return (
    <>
        <AppBarSideNavigation>
            <AppBarTraining />
        </AppBarSideNavigation>
    </>
  )
}

export default TrainingListPage
