import React from 'react'
import AppBarAssessment from '../Components/AppBars/AppBarAssessment';
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation';

const AssessmentPage = () => {
  return (
    <>
      <AppBarSideNavigation>
        <AppBarAssessment />
      </AppBarSideNavigation>
    </>
  )
}

export default AssessmentPage
