import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

function RecruitmentFunnels({ data }) {
  return (
    <SimpleGrid columns={{ base: 8, md: 11}} gap="2" textAlign="center" size="xs">
      <Box>
        <Heading fontSize="sm">{data?.status_unprocessed ? data?.status_unprocessed : 0}</Heading>
        <Text fontSize={10}>Belum diroses</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_review ? data?.status_review : 0}</Heading>
        <Text fontSize={10}>Tinjauan awal</Text>
      </Box>
      {/* <Box>
        <Heading fontSize="sm">0</Heading>
        <Text>Terpilih</Text>
      </Box> */}
      <Box>
        <Heading fontSize="sm">{data?.status_interview ? data?.status_interview : 0}</Heading>
        <Text fontSize={10}>Wawancara</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_assessment ? data?.status_assessment : 0}</Heading>
        <Text fontSize={10}>Asessment</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_onboarding ? data?.status_onboarding : 0}</Heading>
        <Text fontSize={10}>Onboarding</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_training ? data?.status_training : 0}</Heading>
        <Text fontSize={10}>Trainings</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_active ? data?.status_active : 0}</Heading>
        <Text fontSize={10}>Active</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_inactive}</Heading>
        <Text fontSize={10}>Inactive</Text>
      </Box>
      <Box>
        <Heading fontSize="sm">{data?.status_not_suitable ? data?.status_not_suitable :0}</Heading>
        <Text fontSize={10}>Not Suitable</Text>
      </Box>
    </SimpleGrid>
  );
}

export default RecruitmentFunnels;
