import React, { useEffect, useState } from 'react'
import { collection, query, onSnapshot, orderBy, limit, getDocs } from "firebase/firestore";
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation';
import { db } from '../Config/firebase';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import ExportExcel from '../Utils/ExportExcel';

const ThankyouBoxResponsesPage = () => {
    const [results, setResults] = useState([]);

    const getResults = async () => {
        const res = [];
        try {
            const q = query(collection(db, "thankyou_box"), orderBy("timestamp", "desc"), limit(50));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                res.push(doc.data());
            });
            setResults(res)
        } catch (error) {
            console.log(error.message, "error getting Thankyou box responses")
        }
    };

    useEffect(() => {
        getResults();
    }, [])

    return (
        <>

            <AppBarSideNavigation>
                <ExportExcel excelData={results} fileName='Thankyou_box_responses' />
                <TableContainer>
                    <Table bg='white' variant='striped' size='sm'>
                        <Thead>
                            <Tr>
                                <Th>To</Th>
                                <Th>From</Th>
                                <Th>Message</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {results?.length > 0 ?
                                results.map((result, index) => (
                                    <Tr key={index}>
                                        <Td fontWeight='bold'>{result.to}</Td>
                                        <Td>{result.from}</Td>
                                        <Td>{result.message}</Td>
                                    </Tr>
                                ))
                                : null}

                        </Tbody>
                    </Table>
                </TableContainer>
            </AppBarSideNavigation>
        </>
    )
}

export default ThankyouBoxResponsesPage
