import { AspectRatio, Avatar, Box, Flex, Heading, Image, SimpleGrid, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../Context/AppContext';

const AppBarJobsList = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const company = searchParams.get('company');
    let navigate = useNavigate();
    const { availableRoles, selectedCompany, setSelectedCompany, companies } = useAppContext();
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
        xl: true,
    });

    const navigatee = () => {
      console.log(companies);
      navigate({
        search : createSearchParams({
          company : selectedCompany ?? 'pt edrus pasar digital'
        })?.toString()
      })
    };

    useEffect(()=>{
        console.log(searchParams.get('company'), "foo")
    },[]);

  return (
    <>
        <Stack m={5} gap={10}>
          <Box>
              <Text fontWeight={800} onClick={navigatee} cursor='pointer'>Companies</Text>
              <SimpleGrid columns={3} gap="2">
                {companies
                    ? companies.map((company, index) => (
                        <Flex
                          gap={2}
                          bg="white"
                          shadow="md"
                          key={index}
                          p={5}
                          cursor="pointer"
                          _hover={{
                            transform: "scale(1.03)",
                            transition: "60ms linear",
                          }}
                          w="full"
                          onClick={() => navigate({
                              search : createSearchParams({
                                company : company.name?.toLowerCase()
                              })?.toString()
                            })}
                        >
                          <Avatar src={company.image} />
                          <Flex flexDir="column">
                            <Text fontWeight={500}>{company.name}</Text>
                            <Text fontSize={10} fontWeight={400}>{company.industry}</Text>
                          </Flex>
                        </Flex>
                      ))
                    : null}
              </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight={800}>Find Your Dream Jobs</Text>
            <SimpleGrid columns={{base:1, md:2, lg:2, xl:3}} gap="2">
              {availableRoles
                .filter(
                  (data) =>
                    String(data.data.companyId).toLowerCase() ===
                    String(company).toLowerCase()
                )
                .map((x, i) => (
                  <Flex
                    shadow="lg"
                    gap={{base:2,md:3,lg:5,xl:10}}
                    p={2}
                    flexWrap='wrap'
                    key={i}
                    _hover={{
                      transform: "scale(1.04)",
                      transition: "60ms linear",
                      zIndex : 1000
                    }}
                    bg='white'
                    cursor="pointer"
                    onClick={() => navigate(`/talent/jobs/${x.id}`)}
                  >
                        <Avatar
                          src={x.data.companyData.image}
                          alt={x.data.position}
                          rounded='full'
                        />
                    
                    <Box flex={5}>
                      <Heading fontSize="xs" fontWeight={500}>
                        {x.data.position}
                      </Heading>
                      <Text fontSize={10}>{x.data.companyData.name}</Text>
                      <Text fontSize={8} color="gray.600">
                        {x.data.job_location}
                      </Text>
                    </Box>
                  </Flex>
                ))}
            </SimpleGrid>
          </Box>
          </Stack>
    </>
  )
}

export default AppBarJobsList
