import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardEmployeePage from "./Pages/Employee/DashboardEmployeePage";
import JobDetailsPage from "./Pages/Employee/JobDetailsPage";
import JobsEmployeePage from "./Pages/Employee/JobsEmployeePage";
import TalentsEmployeePage from "./Pages/Employee/TalentsEmployeePage";
import TrainingEmployee from "./Pages/Employee/TrainingEmployee";

import Dashboard from "./Pages/Employer/DashboardPage";
import Login from "./Pages/Employer/LoginPage";
import ProfilePage from "./Pages/Employer/EmployeeProfilePage";
import RolesAddPage from "./Pages/Employer/Roles/RolesAddPage";
import RolesViewPage from "./Components/AppBars/AppBarJobsView";
import RolesPage from "./Pages/Employer/RolesPage";
import TalentPage from "./Pages/Employer/TalentPage";
import MainEmployeePage from "./Pages/MainEmployeePage";
import MainEmployerPage from "./Pages/MainEmployerPage";
import MainPage from "./Pages/MainPage";
import SignupPage from "./Pages/SignupPage";
import EmployeeProfilePage from "./Pages/Employer/EmployeeProfilePage";
import EmployerProfilePage from "./Pages/Employer/EmployerProfilePage";
import CandidateViewPage from "./Pages/Employer/Candidates/CandidateViewPage";
import { useAppContext } from "./Context/AppContext";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import CompanyProfilePage from "./Pages/Employer/CompanyProfilePage";
import AppBarEditEmployeeProfile from "./Components/AppBars/AppBarEditEmployeeProfile";
import EmployeeProfileEdit from "./Pages/Employee/EmployeeProfileEdit";
import EmployerProfileEditPage from "./Pages/Employer/EmployerProfileEditPage";
import AdminTrainingPage from "./Pages/Employer/Training/AdminTrainingPage";
import TrainingOverviewPage from "./Pages/Employee/TrainingOverviewPage";
import MainDashboardPage from "./Pages/Employee/MainDashboardPage";
import RolesPreviewPage from "./Pages/Employer/Roles/RolesPreviewPage";
import { Box, Container, Stack } from "@chakra-ui/react";
import EditTrainingPage from "./Pages/Employer/Training/EditTrainingPage";
import AppBarSideNavigation from "./Components/AppBars/AppBarSideNavigation";
import WelcomePage from "./Pages/WelcomePage";
import TrainingListPage from "./Pages/TrainingListPage";
import WealthDynamicTest from "./Pages/WealthDynamicTest";
import JobsListPage from "./Pages/Employee/JobsListPage";
import TeamsPage from "./Pages/TeamsPage";
import JobsViewPage from "./Pages/Employer/Roles/JobsViewPage";
import AssessmentPage from "./Pages/AssessmentPage";
import ThankyouBoxPage from "./Pages/ThankyouBoxPage";
import ThankyouBoxResponsesPage from "./Pages/ThankyouBoxResponsesPage";

function App() {
	const { auth } = useAppContext();
	// const user = auth;

	const x = localStorage.getItem("userData");
	const xx = localStorage.getItem("userGoogleCredential");
	const user = x ? JSON.parse(x) : undefined;
	const userGoogle = xx ? JSON.parse(xx) : "no data";

	return (
		<>
			<Box>
				<Routes>
					{/* Main Page */}
					<Route path="/" element={<MainPage />} />
					<Route path="/employer" element={<MainEmployerPage />} />
					<Route path="/talent" element={<MainEmployeePage />} />
					<Route path="/signup" element={<SignupPage />} />
					<Route path="/wealth-dynamics-assessment" element={<WealthDynamicTest />} />
					<Route path="/thankyou-box" element={<ThankyouBoxPage />} />


					{/* talent page */}
					<Route path="/login" element={<Login />} />
					<Route
						path="/talent/dashboard/"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								<MainDashboardPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/talent/dashboard/"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								<WelcomePage />
								{/* <DashboardEmployeePage /> */}
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/talent/trainings/:company"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								<TrainingListPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/talent/trainings/:company/:id"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								<TrainingOverviewPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/talent/profile"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={auth}
								redirectPath={"/login"}
							>
								<EmployeeProfilePage />
							</ProtectedRoutes>
						}
					/>
					<Route path="/talent/profile-edit" element={<EmployeeProfileEdit />} />
					{/* <Route
						path="/talent/trainings"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								<TrainingEmployee />
							</ProtectedRoutes>
						}
					/> */}

					<Route
						path="/talent/jobs"
						element={
							<ProtectedRoutes
								user={user}
								userGoogle={userGoogle}
								redirectPath={"/login"}
							>
								{/* <JobsEmployeePage /> */}
								<JobsListPage />
							</ProtectedRoutes>
						}
					/>
					<Route path="/talent/jobs/:id" element={<JobDetailsPage />} />

					{/* emoloyer page */}
					<Route path="/login" element={<Login />} />

					{/* <Route
						path="/employer/dashboard"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/employer/login"}
								employer={true}
							>
								<Dashboard />
							</ProtectedRoutes>
						}
					/> */}

					<Route
						path="/teams"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<TeamsPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/profile"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<EmployerProfilePage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/profile-edit"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<EmployerProfileEditPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/company-profile"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<CompanyProfilePage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/jobs-admin"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<RolesPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/thankyou-box-responses"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<ThankyouBoxResponsesPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/assessments"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<AssessmentPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/preview-roles/:id"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<RolesPreviewPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="jobs-admin/roles/view/:id"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<JobsViewPage />
							</ProtectedRoutes>
						}
					/>

					<Route
						path="/roles/view/:id/candidate/:candidateId"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<CandidateViewPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/roles/add"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<RolesAddPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/talents"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<TalentPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/training-admin"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<AdminTrainingPage />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/training-admin/edit/:id"
						element={
							<ProtectedRoutes
								user={user}
								redirectPath={"/login"}
								employer={true}
							>
								<EditTrainingPage />
							</ProtectedRoutes>
						}
					/>
				</Routes>
			</Box>
		</>

	);
}

export default App;
