import {
    Heading, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Modal,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    useDisclosure,
    Flex,
    Stack,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    Select,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import ExportExcel from '../../Utils/ExportExcel';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, startAfter } from 'firebase/firestore';
import { db } from '../../Config/firebase';

const AppBarAssessment = () => {
    const [wdForExport, setWdForExport] = useState([]);
    const [isLocked, setIsLocked] = useState('1');
    const [wdResults, setWdResults] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const passwordRef = useRef();
    const [assessmentDetails, setAssessmentDetails] = useState({});
    const [lastDoc, setLastDoc] = useState({});

    const toast = useToast();



    const getAssessmentDetails = async () => {
        const docRef = doc(db, "assessment", "Wc0w2l2J7nqszd6qDhV0");

        try {
            const docSnap = await getDoc(docRef);
            console.log(docSnap.data(), "docsnap.data()");
            setAssessmentDetails(docSnap?.data())
        } catch (error) {
            console.log(error.message, `error in getting doc`);
        };
    };


    const getWD = async () => {
        const arr = [];
        const q = query(
            collection(db, "assessment", "Wc0w2l2J7nqszd6qDhV0", "results"),
            orderBy('timestamp', 'desc'),
            limit(25)
        );

        const querySnapshot = await getDocs(q);
        // const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            arr.push({ data: doc.data(), id: doc.id });
        });

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastDoc(lastVisible)
        // console.log("roles in context ", arr);
        setWdResults(arr);
        // });
    };


    const fetchNext = async () => {
        let arr = [];
        const next = query(collection(db, "assessment", "Wc0w2l2J7nqszd6qDhV0", "results"),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(10));
        const resultNext = await getDocs(next);

        resultNext.forEach((doc) => {
            arr.push({ data: doc.data(), id: doc.id });
        });

        const prevData = [...wdResults];
        const newData = arr;
        const lastVisible = resultNext.docs[resultNext.docs.length - 1];
        setLastDoc(lastVisible);
        setWdResults([...prevData, ...newData]);
    };

    const printWD = () => {
        let arr = [];

        if (wdResults) {
            for (let elem of wdResults) {
                const { name, house, date, profile, phone } = elem.data;
                const { totalScore: { dynamo, blaze, tempo, steel } } = elem.data;
                arr.push({ name, date, phone, dynamo, blaze, tempo, steel, profile, house })
            };
            setWdForExport(arr)
        } else if (!wdResults) {
            alert('Hasil WD belum ready untuk di-export');
        };
        onOpen();
    };


    const handleChangeLock = async (e) => {
        if (e.target.value === '1') {
            setIsLocked(e.target.value)
        } else {
            setIsLocked(e.target.value)
            const docRef = doc(db, "assessment", "Wc0w2l2J7nqszd6qDhV0");

            try {
                await setDoc(docRef, { isLocked: false, password: '' }, { merge: true });
                toast({
                    title: "Success",
                    description: "Assessment is now not locked",
                    status: "success",
                    isClosable: true,
                    duration: 9000
                })
            } catch (error) {
                console.log(error.message, "error in changing assessment details")
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    isClosable: true,
                    duration: 9000
                })
            }
        };
    };

    const submitPassword = async () => {
        setLoading(true);

        const docRef = doc(db, "assessment", "Wc0w2l2J7nqszd6qDhV0");


        try {
            await setDoc(docRef, { isLocked: isLocked === '1' ? true : false, password: passwordRef.current ?? assessmentDetails?.password }, { merge: true });
            toast({
                title: "Success",
                description: "Edit password submitted",
                status: "success",
                isClosable: true,
                duration: 9000
            })
        } catch (error) {
            console.log(error.message, "error in changing assessment details")
            toast({
                title: "Error",
                description: "Edit password Error" + error.message,
                status: "error",
                isClosable: true,
                duration: 9000
            })
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getWD();
        getAssessmentDetails();
    }, []);


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalBody bg='red.100'>
                        <Heading size='sm' textAlign='center' fontWeight={600}>Print Hasil Wealth Dynamics ke .xlsx?</Heading>
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Export Sekarang
                    </Button> */}
                        <ExportExcel excelData={wdForExport} fileName='Hasil_wealth_dynamics' />
                        <Button mx={2} size='sm' onClick={onClose} variant='ghost'>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex flexDir={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }} gap={{ sm: 20 }} justifyContent="space-between" alignItems="center">
                <Stack>
                    <Heading size='xs'>Wealth Dynamics</Heading>
                    <Button bg='green' color='white' size='xs' onClick={() => printWD()}>Download WD Results</Button>
                </Stack>
                <Stack>
                    <Heading size="sm">Lock Assessment?</Heading>
                    <Text fontSize={10}>Private access or public? Please set password if setting it private.</Text>
                    {/* <RadioGroup size='sm' onChange={handleChangeLock}
                        value={isLocked ? '1' : '2'}
                    >
                        <Stack direction='row'>
                            <Radio value='1'>Lock With Password</Radio>
                            <Radio value='2'>Open</Radio>
                        </Stack>
                    </RadioGroup> */}
                    <Select bg='white' onChange={handleChangeLock} value={isLocked}>
                        <option value='1'>Lock With Password</option>
                        <option value='2'>Open (without password)</option>
                    </Select>
                    {isLocked === '1' ? <>
                        <FormControl>
                            <FormLabel>Password: </FormLabel>
                            <Flex gap={2}>
                                <Input
                                    bg='white'
                                    type='text'
                                    placeholder=''
                                    isRequired
                                    onChange={(e) => { passwordRef.current = e.target.value }}
                                    defaultValue={assessmentDetails?.password}
                                />
                                <Button
                                    colorScheme='green'
                                    onClick={submitPassword}
                                    isLoading={loading}
                                    disabled={loading}
                                    loadingText='Submitting..'
                                >Submit</Button>
                            </Flex>
                            <FormHelperText>Masukkan password untuk <i>applicants</i> bisa mengisi WD Assessment</FormHelperText>
                        </FormControl>
                    </> : <></>}
                </Stack>


            </Flex>
            <TableContainer>
                <Table variant='striped' bg='white' mt={10} size='sm'>
                    <Thead>
                        <Tr>
                            <Th>No</Th>
                            <Th>Nama</Th>
                            <Th>Phone</Th>
                            <Th>Dynamo</Th>
                            <Th>Blaze</Th>
                            <Th>Tempo</Th>
                            <Th>Steel</Th>
                            <Th>D</Th>
                            <Th>B</Th>
                            <Th>T</Th>
                            <Th>S</Th>
                            <Th>Profile</Th>
                            <Th>House</Th>
                        </Tr>
                    </Thead>
                    <Tbody bg='white'>

                        {wdResults ? wdResults?.map((item, index) => (
                            <Tr>
                                <Td>{index++ + 1}</Td>
                                <Td>{item?.data?.name}</Td>
                                <Td>{item?.data?.phone}</Td>
                                <Td>{item?.data?.count?.dynamo}</Td>
                                <Td>{item?.data?.count?.blaze}</Td>
                                <Td>{item?.data?.count?.tempo}</Td>
                                <Td>{item?.data?.count?.steel}</Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.totalScore?.dynamo}</Text></Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.totalScore?.blaze}</Text></Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.totalScore?.tempo}</Text></Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.totalScore?.steel}</Text></Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.profile?.toUpperCase()}</Text></Td>
                                <Td bg='red.100'><Text fontWeight='bold'>{item?.data?.house?.toUpperCase()}</Text></Td>
                            </Tr>
                        )) : null}
                    </Tbody>
                </Table>
                <Button colorScheme='green' onClick={fetchNext}>Load more</Button>
            </TableContainer>
        </>
    )
}

export default AppBarAssessment
