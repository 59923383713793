import { Box, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react'
import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../Config/firebase';

const AppBarTraining = () => {
    const [trainings, setTrainings] = useState([]);
    const params = useParams();
    let navigate = useNavigate();
    const { company } = params;

    const getTrainings = async () => {
        try {
        const q = query(
            collection(db, "company", params?.company?.toLowerCase(), "trainings")
        );
        let arr = [];
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setTrainings(arr);
        } catch (error) {
        console.log(error.message);
        }
    };

    useEffect(() => {
        getTrainings();
    }, []);
  return (
    <>
       <Box m={5}>
            <Text fontWeight={800}>Trainings</Text>
            <SimpleGrid columns={2} gap="2">
              {trainings.map((training, i) => (
                <Box
                  cursor={"pointer"}
                  _hover={{
                    transform: "scale(1.1)",
                    transition: "60ms linear",
                  }}
                  key={i}
                  onClick={() =>
                    navigate(
                      `/talent/trainings/${params.company}/${training.name}`,
                      { state: { training: training } }
                    )
                  }
                >
                  <Image src={training.thumbnail} alt="Dan Abramov" />
                  <Heading fontSize="md">{training.name}</Heading>
                  <Text>{training.videoList.length} videos</Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
    </>
  )
}

export default AppBarTraining
