import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {
  Button,
  Center,
  Container,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Spinner,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { useAppContext } from "../Context/AppContext";
import { authFirebase, db } from "../Config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";

function SignupPage() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [signUpData, setSignUpData] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { loadingShow, loadingClose } = useAppContext();
  let navigate = useNavigate();

  const handleSignUp = async () => {
    setLoading(true);
    // console.log(signUpData,'ini data sign up')
    if (!signUpData.name) {
      alert("Nama harus diisi!");
    } else if (signUpData.name && !signUpData.email) {
      alert("Email harus diisi!");
    } else if (signUpData.name && signUpData.email && !signUpData.password) {
      alert("Password harus diisi!");
    } else if (
      signUpData.name &&
      signUpData.email &&
      signUpData.password &&
      !signUpData.confirmPassword
    ) {
      alert("Konfirmasi password harus diisi!");
    } else if (
      signUpData.name &&
      signUpData.email &&
      signUpData.password &&
      signUpData.confirmPassword &&
      signUpData.password !== signUpData.confirmPassword
    ) {
      alert("Maaf, konfirmasi password tidak sama dengan password..");
    } else if (
      signUpData.name &&
      signUpData.email &&
      signUpData.password &&
      signUpData.confirmPassword &&
      signUpData.password == signUpData.confirmPassword
    ) {
      const displayName = signUpData.name;
      try {
        await createUserWithEmailAndPassword(
          authFirebase,
          signUpData.email,
          signUpData.password
        ).then(async (userCredential) => {
          await updateProfile(authFirebase.currentUser, {
            displayName,
          });
          sendEmailVerification(authFirebase.currentUser);
          // Signed in
          const user = userCredential.user;

          console.log("userCredential:", userCredential);
          console.log("userCredential.user", userCredential.user);

          if (user) {
            toast({
              title: `Berhasil membuat akun baru dengan email: ${user.email}`,
              message: "Silakan login dengan email dan password",
              color: "green",
              duration: 5000,
            });
            await setDoc(doc(db, "users", user.uid), {
              name: user.displayName,
              email: user.email,
              uid_user: user.uid,
              role: signUpData.role,
              company: signUpData.company ? signUpData.company : null,
              enrollmentDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
              createdAt: new Date(),
            });
            navigate(`/${signUpData.role}/dashboard`, { replace: true });
          }
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);

        alert(error.message);
      }
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") handleSignUp();
  };

  return (
    <Container mt="10%">
      <Heading textAlign="center">WELCOME TO INTRAPRENEUER</Heading>
      <Center>
        <Heading size="xs">Please fill this signup form</Heading>
      </Center>

      <FormControl mt={5}>
        <Text>Name</Text>
        <Input
          type="text"
          placeholder="name"
          id="name"
          bg="white"
          onChange={(e) =>
            setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
          }
        />
      </FormControl>
      <FormControl mt={5}>
        <Text>Role</Text>
        <Select
          placeholder="please pick role"
          defaultValue="null"
          id="role"
          onChange={(e) =>
            setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
          }
        >
          <option value="employer">Employer</option>
          <option value="employee">Employee</option>
        </Select>
      </FormControl>

      {signUpData.role == "employer" ? (
        <FormControl mt={5}>
          <Text>Company</Text>
          <Input
            type="text"
            placeholder="company"
            id="company"
            bg="white"
            onChange={(e) =>
              setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
            }
          />
          <FormHelperText color="red.600">
            * Gunakan huruf kecil semua (contoh: pt edrus strategi digital)
          </FormHelperText>
        </FormControl>
      ) : null}

      <FormControl mt={5}>
        <Text>Email</Text>
        <Input
          type="email"
          placeholder="email"
          id="email"
          bg="white"
          onChange={(e) =>
            setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
          }
        />
      </FormControl>
      <FormControl mt={5}>
        <Text>Password</Text>
        <Input
          type="password"
          placeholder="password"
          id="password"
          bg="white"
          onChange={(e) =>
            setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
          }
        />
      </FormControl>
      <FormControl mt={5}>
        <Text>Password</Text>
        <Input
          type="password"
          placeholder="confirm password"
          id="confirmPassword"
          bg="white"
          onChange={(e) =>
            setSignUpData({ ...signUpData, [e.target.id]: e.target.value })
          }
          onKeyDown={handleKeyDown}
        />
      </FormControl>
      <Button
        mt="2"
        width="full"
        bgColor="brand.100"
        onClick={() => handleSignUp()}
        disabled={loading ? true : false}
      >
        Sign Up {loading ? <Spinner mx={5} /> : null}
      </Button>

      <Spacer />
    </Container>
  );
}

export default SignupPage;
