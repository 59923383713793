import React from "react";
import {
  Avatar,
  Box,
  Center,
  Heading,
  Image,
  HStack,
  SimpleGrid,
  Spacer,
  Flex,
  ButtonGroup,
  Button,
  Text,
  useBreakpointValue,
  Container,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

function AppBarHeaderMain() {
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <>
      <Box as="section" bg="#ffd600">
        <Box as="nav" bg="bg-accent" color="on-accent">
          <Container>
            <Flex justify="space-around">
              <HStack spacing="4">
                <Image
                  src={require("../../Image/logo_intra_black.png")}
                  maxW="150px"
                  py={1}
                />
                {isDesktop && (
                  <ButtonGroup variant="ghost-on-accent" spacing="1">
                    <Button onClick={() => navigate("roles")}>Talent</Button>
                    <Button onClick={() => navigate("talents")}>
                      Employer
                    </Button>
                  </ButtonGroup>
                )}
              </HStack>
            </Flex>
          </Container>
        </Box>
      </Box>
      {/* <Box width="full" height="16" bgColor="#ffd600">
        <HStack alignContent="space-evenly">
          <Link to="/">
            <Image
              src={require("../../Image/logo_intra_black.png")}
              maxW="150px"
              py={1}
            />
          </Link>

          <Spacer />
          <Link to="/employee">Talent</Link>
          <Link to="/employer">Employer</Link>
          <Spacer />
        </HStack>
      </Box> */}
    </>
  );
}

export default AppBarHeaderMain;
