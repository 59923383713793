import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppBarHeader from "./AppBarHeaderEmployer";
import RecruitmentFunnels from "../AppComponents/RecruitmentFunnels";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
import { AppBarHeaderNew } from "./AppBarHeaderNew";
import CandidateCard from "../AppComponents/CandidateCard";
import RecruitmentFunnelsBig from "../AppComponents/RecruitmentFunnelsBig";

function AppBarJobsView() {
  const [candidates, setCandidates] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [countAll, setCountAll] = useState(0)
  const [roleData, setRoleData] = useState({});
  const [filteredArray, setFilteredArray] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const { availableRoles } = useAppContext();
  const params = useParams();
  let navigate = useNavigate();
  const roleInView = availableRoles.filter((x) => x.id == params.id)[0];
  const [shouldSearch, setShouldSearch] = useState(false);

  const list = [
    { display: "Semua", name: "all", number: candidates.length },
    {
      display: "Belum diproses",
      name: "unprocessed",
      number: roleInView?.data?.status_unprocessed || 0,
    },
    {
      display: "Tinjauan Awal",
      name: "review",
      number: roleInView?.data?.status_review || 0,
    },
    {
      display: "Terpilih",
      name: "suitable",
      number: 0,
    },
    {
      display: "Wawancara",
      name: "interview",
      number: roleInView?.data?.status_interview || 0,
    },
    {
      display: "Assessment",
      name: "assessment",
      number: roleInView?.data?.status_assessment || 0,
    },
    {
      display: "Onboarding",
      name: "onboarding",
      number: roleInView?.data?.status_onboarding ||0,
    },
    {
      display: "Trainings",
      name: "training",
      number: roleInView?.data?.status_training || 0,
    },
    {
      display: "Active",
      name: "active",
      number: roleInView?.data?.status_active || 0,
    },
    {
      display: "Inactive",
      name: "inactive",
      number: roleInView?.data?.status_inactive || 0,
    },
    {
      display: "Tidak Terpilih",
      name: "not_suitable",
      number: roleInView?.data?.status_not_suitable || 0,
    },
  ];




  const getCandidates = async () => {
    const docRef = doc(db, "roles", params.id);
    try {
      const docSnap = await getDoc(docRef);
      setRoleData(docSnap.data());
      // console.log({ data: docSnap.data() });
    } catch (error) {
      console.log(error.message, `error in getting ${params.id} doc`);
    }

    const q = query(collection(db, "roles", params.id, "candidates"));
    let arr = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push({ data: doc.data(), id: doc.id });
    });

    // console.log("arr of candidates,", arr);
    setCandidates(arr);
    const snapshot = await getCountFromServer(q);
    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     if (!arr.includes)
    //     arr.push({ data: doc.data(), id: doc.id });
    //   });

    //   console.log("arr of candidates,", arr);
    //   setCountAll(snapshot.data().count);
    //   setCandidates(arr);
    // });

    const arrayOfUnprocessed = arr.filter(x=>x.data.status == "unprocessed").length
    const arrayOfReview = arr.filter(x=>x.data.status == "review").length
    const arrayOfSelected = arr.filter(x=>x.data.status == "selected").length
    const arrayOfInterview = arr.filter(x=>x.data.status == "interview").length
    const arrayOfAssessment = arr.filter(x=>x.data.status == "assessment").length
    const arrayOfOnboarding = arr.filter(x=>x.data.status == "onboarding").length
    const arrayOfTraining = arr.filter(x=>x.data.status == "training").length
    const arrayOfNotSuitable= arr.filter(x=>x.data.status == "not_suitable").length
    const arrayOfActive= arr.filter(x=>x.data.status == "active").length
    const arrayOfInactive= arr.filter(x=>x.data.status == "inactive").length


    const roleRef = doc(db, "roles", params.id);
    try {    
      await updateDoc(roleRef, {
        status_active:arrayOfActive,
        status_unprocessed: arrayOfUnprocessed,
        status_assessment:arrayOfAssessment,
        status_inactive : arrayOfInactive,
        status_interview : arrayOfInterview,
        status_onboarding : arrayOfOnboarding,
        status_review : arrayOfReview,
        status_training : arrayOfTraining,
        status_not_suitable : arrayOfNotSuitable,
    })
      
    } catch (error) {
      console.log(error.message, "error in updating roleRef")
    }
  };

    const handleMove = async (value, x) => {
    const selected = candidates.filter((item) => item.id == x.id);
    console.log(value, x.id);
    console.log("before:", selected[0].data.status);

    const candidateRef = doc(db, "roles", params.id, "candidates", x.id);


    try {
      await setDoc(candidateRef, { status: value }, { merge: true });
    } catch (error) {
      console.log(error.message, "error in changing candidate status")
    }


    getCandidates()
    // window.location.reload();
  };

  function compare(a, b) {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    getCandidates();
  }, []);



  const SearchComponent = () => {
      const [search, setSearch] = useState({});


      const searchChange = (key, value) =>{
        let newState = search;
        newState[key] = value;
        console.log(newState);
        setSearch(newState);
      };

      const handleSearch = () => {
        const newArr = candidates.filter((candidate) => {
          return String(candidate.data[search.key])
            .toLowerCase()
            .includes(search.value.toLowerCase());
        });
        setFilteredArray(newArr);
        setShouldSearch(true);
      };

      const handleClear = () =>{
        setSearch({});
        setShouldSearch(false);
      };

    
    return (
      <Box Box m="2" p="2" bgColor="white" borderRadius="md">
        <Text fontSize={13}>Cari kandidat menggunakan kata kunci</Text>

        <HStack>
          <Input
          size='sm'
            type="value"
            maxW="sm"
            id="key"
            onChange={(e) => searchChange(e.target.id, e.target.value)}
          />
          <Select
          id="key"
          size='sm'
          fontSize={13}
            placeholder="Field"
            maxW="32"
            onChange={(e) => searchChange(e.target.id, e.target.value)}
          >
            <option value="name">Nama</option>
            <option value="email">Email</option>
            <option value="major">Jurusan</option>
            <option value="school">Universitas</option>
          </Select>
          <Button bgColor="brand.100" size='sm' onClick={handleSearch}>Cari</Button>
          <Button bgColor="cyan.100" size='sm' onClick={handleClear}>Reset</Button>
          {/* <HStack bgColor="white" p="2" borderRadius="md">
              <Checkbox p="2" />
              <Text>Pilih semua</Text>
            </HStack> */}

          <Spacer />
          <HStack bgColor="white" p="2" borderRadius="md">
            <Text>Urutkan</Text>
            <Select
              placeholder="Timestamp"
              maxW="40"
              size="sm"
              onChange={(e) => console.log(e.target.value)}
            >
              <option value="timestamp">Paling Baru</option>
              <option value="timestamp">Paling Lama</option>
              <option value="name">Nama</option>
              <option value="status">Status</option>
              <option value="major">Jurusan</option>
              <option value="school">Universitas</option>
            </Select>
          </HStack>
        </HStack>
      </Box>
    );
  };


  const CandidateListComponent = () => {
    const filtered =
      tabIndex !== 0
        ? candidates.filter((x) => x.data.status == list[tabIndex].name)
        : candidates;

    return (
      <Box 
      display='flex' 
      justifyContent='center'> 
      <Stack  w='80%'>
        {!shouldSearch && 
        candidates.length > 0
          ? filtered.map((candidate, index) => (
              <CandidateCard
                handleMove={handleMove}
                x={candidate}
                key={index}
                navigate={navigate}
              />
            ))
          : 
          shouldSearch &&
          filteredArray.length > 0
          ? filteredArray.map((candidate, index) => (
              <CandidateCard
                handleMove={handleMove}
                x={candidate}
                key={index}
              />
            ))
          : null}
      </Stack>

      </Box>
    );
  };


  return (
    <>
      <Box w="full" bg="gray.100">
        <Box p="2" bgColor="gray.50" borderRadius="md">
          <Flex
            flexDirection={{ base: "column", md: "column", lg: "row" }}
            gap="4"
          >
            <Link to="/jobs-admin">
              <ArrowLeftIcon />
            </Link>
            <VStack w="50%">
              <Heading fontSize="lg">{roleData?.position}</Heading>
              <Heading fontSize="sm" color="gray.500" noOfLines={5}>
                {roleData?.job_responsibility}
              </Heading>
            </VStack>
            <Spacer />

            {/* <RecruitmentFunnelsBig role={roleInView || {}} /> */}
          </Flex>
        </Box>
        <Tabs
          mt={20}
          variant="enclosed"
          size="sm"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            {list.length > 0 &&
              list.map((item, i) => (
                <Tab key={i}>
                  <Text>{item.display}</Text>
                  <Badge
                    variant="solid"
                    colorScheme={item.number !== 0 ? "red" : "gray"}
                    mb={2}
                  >
                    {item.number}
                  </Badge>
                </Tab>
              ))}
          </TabList>
          <SearchComponent />

          <CandidateListComponent />
        </Tabs>
      </Box>
    </>
  );
}

export default AppBarJobsView;
