import React, { useEffect, useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from "firebase/auth";

import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Image,
  Input,
  Spacer,
  Spinner,
  Text,
  Toast,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { authFirebase, db } from "../../Config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useAppContext } from "../../Context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import moment from "moment";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [userCredential, setUserCredential] = useState({});
  const [url, setUrl] = useState("");
  const toast = useToast();
  const { loadingShow, loadingClose } = useAppContext();
  let navigate = useNavigate();

  const provider = new GoogleAuthProvider();

  const pendingLocation = localStorage.getItem("pendingLocation");

  const handleLogin = async () => {
    setLoading(true);
    if (password !== "" && password !== "") {
      // loadingShow();

      //login response
      try {
        const responseLogin = await signInWithEmailAndPassword(
          authFirebase,
          email,
          password
        );

        // console.log(responseLogin)

        //get user data
        const docRef = doc(db, "users", responseLogin.user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap) {
          localStorage.setItem("userData", JSON.stringify(docSnap.data()));
        } else console.log("no docSnap");

        if (docSnap.data().role == "employer" && docSnap.data().company) {
          const docRef2 = doc(db, "company", docSnap.data().company);
          const docSnap2 = await getDoc(docRef2);

          // console.log("saving this to localstorage", docSnap2.data());

          localStorage.setItem("companyData", JSON.stringify(docSnap2.data()));
          localStorage.setItem("companyId", docSnap2.id);
        }
        setLoading(false);

        if (pendingLocation) {
          window.location.href = pendingLocation;
        } else {
          if (window.location.href.includes("employer")) {
            navigate("/employer/dashboard");
          } else {
            navigate("/talent/dashboard");
          }
        }
      } catch (error) {
        toast({
          title: "Your request cannot be processed",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        // loadingClose();
        setLoading(false);
      }
    }
    // loadingClose();
  };

  const handleLoginGoogle = async () => {
    signInWithPopup(authFirebase, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // navigate("/employee/dashboard");
        if (pendingLocation) {
          window.location.href = pendingLocation;
        } else {
          navigate("/talent/dashboard");
        }
        if (user) {
          console.log(user);
          setUserCredential(user);
          localStorage.setItem("userGoogleCredential", JSON.stringify(user));
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    // signInWithRedirect(authFirebase, provider);
    try {
      const uploadedData = await setDoc(
        doc(db, "users", userCredential.uid),
        {
          name: userCredential.displayName,
          email: userCredential.email,
          uid_user: userCredential.uid,
          role: "employee",
          enrollmentDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
          createdAt: new Date(),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const getUrl = () => {
      return window.location.href;
    };
    setUrl(getUrl());
  }, [window.location.href]);

  const handleKeyDown = (e) => {
    if (e.key == "Enter") handleLogin();
  };

  return (
    <Container mt="10%">
      <Spacer />
      <HStack>
        <Heading textAlign="center">INTRAPRENEUER</Heading>
        <Badge
          variant={url.includes("employer") ? "solid" : "outline"}
          colorScheme="green"
        >
          {url.includes("employer") ? "EMPLOYER" : "TALENT"}
        </Badge>
      </HStack>
      <Text>Email</Text>
      <Input
        type="email"
        bg="white"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Text>Password</Text>
      <Input
        type="password"
        bg="white"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button
        mt="2"
        width="full"
        bgColor="brand.100"
        onClick={(e) => handleLogin(e)}
        disabled={loading ? true : false}
      >
        Sign In {loading ? <Spinner mx={5} /> : null}
      </Button>

      <HStack p="2">
        <Text fontSize={12}>Forget Password</Text>
        <Spacer />
        <Link to="/signup">
          <Text fontSize={12}>Sign Up</Text>
        </Link>
        <Spacer />
        <Link
          to={url.includes("employer") ? "/employee/login" : "/employer/login"}
        >
          <Text fontSize={12}>
            Login As {url.includes("employer") ? "Employee" : "Employer"}
          </Text>
        </Link>
      </HStack>

      {/* <VStack mt={50}> */}
      <Box
        borderWidth={1}
        w="full"
        mt={50}
        cursor="pointer"
        onClick={handleLoginGoogle}
        _hover={{ transform: "scale(1.05" }}
      >
        <Center>
          <Text>Sign in with Google </Text>

          <Image
            src="https://companieslogo.com/img/orig/GOOG-0ed88f7c.png?t=1633218227"
            maxW={5}
            m={3}
          />
        </Center>
      </Box>
      {/* </VStack> */}
    </Container>
  );
}

export default Login;
