import {
	Heading,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	useDisclosure,
	HStack,
	Spacer,
	Button,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Stack,
	FormControl,
	FormLabel,
	Input,
	useToast,
	Spinner,
	Image,
	Text,
	Box,
	SimpleGrid,
	Badge,
	Table,
	Thead,
	Th,
	Tbody,
	Td,
	Tr,
	TableContainer,
	TableCaption,
	Flex,
} from "@chakra-ui/react";
import {
	addDoc,
	collection,
	getDocs,
	query,
	serverTimestamp,
	updateDoc,
	doc,
	arrayRemove
} from "firebase/firestore";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
import Swal from 'sweetalert2';
import {AccordionList, AccourdionList} from '../AppComponents/AccordionList'
import getYouTubeID from 'get-youtube-id';
import { RxCross2 } from'react-icons/rx'
import { FaBeer } from 'react-icons/fa';


const AppBarTrainingAdmin = () => {
	const [data, setData] = useState({});
	const [name, setName] = useState("");
	const [thumbnail, setThumbnail] = useState("");
	const [loading, setLoading] = useState(false);
	const [trainings, setTrainings] = useState([]);
	const [editData, setEditData] = useState({});
	const [trainingList, setTrainingList] = useState([]);
	let navigate = useNavigate();
	const toast = useToast();
	const { isOpen : isOpenAdd, onOpen : onOpenAdd, onClose : onCloseAdd } = useDisclosure();

	const { companyId } = useAppContext();

	const handleAdd = () => {
		setTrainingList([...trainingList, {}]);
	};

	const handleVideoChange = (index, e) => {
		const newTrainingList = [...trainingList];
		newTrainingList[index][e.target.id] = e.target.value;
		setTrainingList(newTrainingList);
		console.log(newTrainingList);
	};

	const handleData = (key, value) => {
		let newObject = data;
		newObject[key] = value;
		setData(newObject);
	};

	const handleSave = async () => {
		setLoading(true);
		try {
			const addedData = await addDoc(
				collection(db, "company", companyId, "trainings"),
				{
					name: name,
					thumbnail: thumbnail,
					videoList: trainingList,
					timestamp: serverTimestamp(),
					createdAt: Date.now().toString(),
				}
			);
			toast({
				title: "Save training success",
				description: `Document written with ID: ${addedData.id})`,
				status: "success",
				isClosable: true,
			});
			setLoading(false);
		} catch (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				isClosable: true,
			});
			setLoading(false);
		}
		setLoading(false);
		onCloseAdd();
	};

	const getTrainings = async () => {
		const q = query(collection(db, "company", companyId, "trainings"));
		let arr = [];

		try {
			let querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				arr.push({id : doc.id, data : doc.data()});
			});
			console.log("this is trainings", arr);
			setTrainings(arr);
		} catch (error) {
			console.log(error.message, "error in getting trainings");
		}
	};

	const onDelete = async ( id, video ) => {
		setLoading(true)
		try {
			const deleteRef = doc(db, 'company', companyId, 'trainings', id);
			await updateDoc(deleteRef, { videoList: arrayRemove(video) });
			setLoading(false);
			Swal.fire({
				icon: 'success',
				title: `Success`,
				text: `Deleted: ${video.title} - (id : ${video.id})`,
			})

		} catch (error) {
			 Swal.fire({
					icon: 'error',
					title: 'Error deleting video',
					text: error.message,
				});
				setLoading(false);
		}
	};

	const handleDelete = ( training, video ) => {
		console.log({video});
		console.log('id :', training.id);
		
		Swal.fire({
			icon: 'question',
			title: `Kamu ingin menghapus ${video.title} ?`,
			text: `${video.title} - (id : ${video.id})`,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Okay, delete',
			denyButtonText: `Cancel`,
		})
		
		.then(result => {
			if (result.isConfirmed) {
					onDelete(training.id, video);  //delete video on confirm
			} else if (result.isDenied) {
				console.log('denied')
			}
		})
	};


	useEffect(() => {
		getTrainings();
	}, []);

	const handleNavigateEdit = ( training ) => {
		navigate(`edit/${training.id}`, { state: {training} })
	};

	const deleteTrainingList = (i) => {
		console.log("index to be deleted: ", i);
		// setTrainingList(trainingList?.filter(x => x.));
		// setTrainingList(trainingList?.splice(i, 1));
		// console.log(trainingList?.splice(i, 1));
		 setTrainingList(prevList => prevList.filter((video, index) => index !== i));
	};

	const closeModal = () => {
		onCloseAdd();
		setTrainingList([]);
	};

	return (
		<>
				<Box justifyContent="center" justify="center">
					<HStack m={2}>
						<Heading fontWeight={500} size="md">
							Trainings Available on <strong>{companyId?.toUpperCase()}</strong> :
						</Heading>
						<Spacer />
						<Button
							bg="green"
							color="white"
							_hover={{ color: "#ffd600", bg: "gray.600" }}
							onClick={onOpenAdd}
						>
							Tambah Training +
						</Button>
					</HStack>

					{/* <ModalAdd /> */}
					<Modal isOpen={isOpenAdd} onClose={onCloseAdd} key="alknfokansf">
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Add New Training</ModalHeader>

							<ModalBody>
								<Stack spacing={8}>
									<FormControl isRequired>
										<FormLabel>Training Name</FormLabel>
										<Input
											placeholder="Content Creation"
											onChange={(e) => setName(e.target.value)}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Thumbnail URL (optional)</FormLabel>
										<Input
											placeholder="url"
											onChange={(e) => setThumbnail(e.target.value)}
										/>
									</FormControl>


									{trainingList?.map((video, i) => (
										<Box key={i} p={2} borderWidth={2} position='relative'>
											<Box position='absolute' top={0} right={0} cursor='pointer' _hover={{ transform: "scale(1.1)",
												transition: "60ms linear", bg: 'red'}}
												onClick={()=>deleteTrainingList(i)}
												// onClick={()=>console.log(trainingList[i],'index : ' ,i)}
												>
												<RxCross2 />
											</Box>
											<FormControl isRequired>
												<FormLabel fontSize="xs">Video Title {i + 1}</FormLabel>
												<Input
													placeholder="e.g. Content Creation"
													id="title"
													onChange={(e) => handleVideoChange(i, e)}
													value={trainingList[i].title}
												/>
											</FormControl>
											<FormControl isRequired>
												<FormLabel fontSize="xs">Video URL {i + 1}</FormLabel>
												<Input
													placeholder="e.g. https://www.youtu.be/ntDF4mvnbuk"
													id="url"
													onChange={(e) => handleVideoChange(i, e)}
													value={trainingList[i].url}
												/>
											</FormControl>
										</Box>
									))}
									<Button bg="red.500" color="white" onClick={handleAdd}>
										Add Video
									</Button>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button
									colorScheme="blue"
									mr={3}
									onClick={handleSave}
									disabled={loading}
								>
									Save
								</Button>
								<Button variant="ghost" onClick={closeModal}>
									Cancel {loading ? <Spinner mx={5} /> : null}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
					<TableContainer>
						<Table variant='striped' colorScheme='teal' size='sm'>
							<TableCaption>Videos, lessons, sections</TableCaption>
							<Thead size='sm'>
								<Tr>
									<Th>Name</Th>
									<Th>Description</Th>
									<Th>Lessons</Th>
									<Th>Action</Th>
								</Tr>
							</Thead>
							<Tbody>
								{trainings?.length > 0 && trainings?.map((training, index) => {
									return (
										<>
											<Tr key={index} size='sm' cursor='pointer' onClick={()=>console.log(training, 'index : ',index)}>
												<Td><strong>{training.data?.name}</strong></Td>
												<Td>{training.data?.description || "Apa itu KYC? Mengapa mengenal dan mengetahui permasalahan pelanggan sangat penting bagi kesuksesan marketing"}</Td>
												<Td>{training.data?.videoList?.length || "23"} videos</Td>
												<Td textAlign="left" mx={5}>
													<Flex gap={1} padding={0}>
														<Button size='xs' onClick={()=>handleNavigateEdit(training)} color='white' bg='red'>Delete</Button>
														<Button size='xs' onClick={()=>handleNavigateEdit(training)} color='white' bg='orange'>Edit</Button>
													</Flex>
												</Td>
											</Tr>
										</>
									)
								})}
								
							</Tbody>
						</Table>

					</TableContainer>
				</Box>
		</>
	);
};

export default AppBarTrainingAdmin;
											// <Tr key={index}>
											// 	<Td as="span" flex="1" textAlign="left" mx={5}>
											// 		{training.data?.name}
											// 	</Td>
											// 	<Td as="span" flex="1" textAlign="left" mx={5}>
											// 		{training.data?.description || "description"}
											// 	</Td>
											// 	<Td as="span" flex="1" textAlign="left" mx={5}>
											// 		{training.data?.videoList?.length || "23"} videos
											// 	</Td>
											// 	<Td as="span" flex="1" textAlign="left" mx={5}>
											// 		<Button onClick={()=>handleNavigateEdit(training)}>E</Button>
											// 	</Td>
											// </Tr>