import React from 'react'
import AppBarThankyouBox from '../Components/AppBars/AppBarThankyouBox';
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation';

const ThankyouBoxPage = () => {
  return (
    <>
        {/* <AppBarSideNavigation> */}
            <AppBarThankyouBox />
        {/* </AppBarSideNavigation> */}
    </>
  )
};

export default ThankyouBoxPage
