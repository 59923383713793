import { Heading } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom';

const AppBarMain = () => {
  const params = useParams();
  return (
    <>
      <Heading>This is AppBarMain</Heading>
      <Heading>{params.company}</Heading>
    </>
  )
}

export default AppBarMain
