import {
  ArrowLeftIcon,
  CalendarIcon,
  CheckIcon,
  InfoIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  List,
  ListIcon,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBarHeader from "../../../Components/AppBars/AppBarHeaderEmployer";
import { AppBarHeaderNew } from "../../../Components/AppBars/AppBarHeaderNew";
import { authFirebase, db } from "../../../Config/firebase";
import { useAppContext } from "../../../Context/AppContext";

function RolesAddPage() {
  const [data, setData] = useState({});
  const [gaji, setGaji] = useState(false);
  const [val, setVal] = useState([]);
  const [val2, setVal2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [length, setLength] = useState(2);
  const [add, setAdd] = useState(false);
  const toast = useToast();
  const { loadingClose, loadingShow, companyId, companyData } = useAppContext();
  const { userData } = useAppContext();
  const navigate = useNavigate();

  const basicInput = [
    { id: "position", name: "Job Title", type: "text" },
    { id: "job_location", name: "Location", type: "text" },
    {
      id: "company",
      name: "Company",
      type: "text",
      disabled: true,
      default: companyId ? companyId : String(companyData.name).toLowerCase(),
    },
    { id: "expiry", name: "Iklan Berakhir pada", type: "date" },
  ];
  const jobType = [
    { name: "Digital Marketer" },
    { name: "Web Programmer" },
    { name: "Digital Marketer" },
    { name: "Digital Marketer" },
  ];
  const handleAddJobDesc = () => {
    let abc = [...val, []];
    setVal(abc);
  };
  const handleAddCriteria = () => {
    let abc = [...val2, []];
    setVal2(abc);
  };

  const handleChangeJobDesc = (e, i) => {
    const inputData = [...val];
    inputData[i] = e.target.value;
    setVal(inputData);
    // console.log(val, "data");
    setData({ ...data, job_descriptions: val });
    console.log({ ...data, job_descriptions: val });
  };

  const handleChangeCriteria = (e, i) => {
    const inputData = [...val2];
    inputData[i] = e.target.value;
    setVal2(inputData);
    console.log(inputData, "data");
    setData({ ...data, job_criteria: val2 });
    // console.log({ ...data, job_criteria: val2 });
  };

  const handleData = (value, x) => {
    setData({ ...data, [x.id]: value });
    // console.log({ ...data, [x.id]: value });
  };

  const handleSalary = (key, value) => {
    setData({ ...data, [key]: value });
    // console.log({ ...data, [key]: value });
  };

  const handleSave = async () => {
    // loadingShow();
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, "roles"), {
        ...data,
        companyId: companyId,
        companyData: companyData,
        status: true,
        postedBy: authFirebase.currentUser.displayName ?? '',
        email: authFirebase.currentUser.email,
        status_unprocessed: 0,
        status_active: 0,
        status_assessment: 0,
        status_inactive: 0,
        status_interview: 0,
        status_onboarding: 0,
        status_process: 0,
        status_review: 0,
        status_training: 0,
        total_submission: 0,
        total_views: 0,
        timestamp: serverTimestamp(),
        createdAt: Date.now().toString(),
      });
      console.log("Document written with ID: ", docRef.id);
      // loadingClose();
      setLoading(false);
      toast({
        title: "Publish Success",
        description: `${data.position} successfully added with id ${docRef.id}`,
        status: "success",
        isClosable: true,
      });
      navigate("/employer/roles");
    } catch (error) {
      // loadingClose();
      setLoading(false);

      toast({
        title: "Something wrong happened...",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleType = (value) => {
    console.log(value);
    if (value !== "add new type") {
      setData({ ...data, job_type: value });
    } else {
      setAdd(true);
    }
  };

  return (
    <>
      {/* <AppBarHeaderNew /> */}
      {/* <Button onClick={() => console.log(authFirebase.currentUser)}>Goo</Button> */}
      <SimpleGrid columns="2">
        <Box>
          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <HStack>1
              <Link to="/employer/roles">
                <ArrowLeftIcon />
              </Link>
              <Heading>Add new Role</Heading>
            </HStack>
          </Box>
          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <Heading fontSize="xl">Program / Job Description</Heading>
            <Textarea
              bg="white"
              size="sm"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
            <SimpleGrid columns="2" gap="2">
              {basicInput.map((x, i) => (
                <Box key={i}>
                  <Text>{x.name}</Text>
                  <Input
                    type={x.type}
                    bg="white"
                    defaultValue={x.default ? x.default : null}
                    disabled={x.disabled ? x.disabled : false}
                    onChange={(e) => handleData(e.target.value, x)}
                  />
                </Box>
              ))}
              <Box>
                <HStack>
                  <Checkbox onChange={(e) => setGaji(e.target.checked)} />
                  <Text>Range gaji</Text>
                </HStack>
                {gaji ? (
                  <HStack>
                    <Input
                      type="text"
                      placeholder="IDR 4,200,000"
                      bg="white"
                      id="range_salary_low"
                      onChange={(e) =>
                        handleSalary(e.target.id, e.target.value)
                      }
                    />
                    <Text>-</Text>
                    <Input
                      placeholder="IDR 5,500,000"
                      type="text"
                      bg="white"
                      id="range_salary_high"
                      onChange={(e) =>
                        handleSalary(e.target.id, e.target.value)
                      }
                    />
                  </HStack>
                ) : (
                  <>
                    <Input type="text" bg="white" value="Negotiable" disabled />
                  </>
                )}
              </Box>
              <Box>
                <Text>Pengalaman</Text>
                <Select
                  placeholder="Jawaban"
                  bgColor="white"
                  onChange={(e) =>
                    setData({ ...data, experience: e.target.value })
                  }
                >
                  <option value="Tanpa Pengalaman">Tanpa Pengalaman</option>
                  <option value="6 bulan - 1 tahun">6 bulan - 1 tahun</option>
                  <option value="1 tahun - 2 tahun">1 tahun - 2 tahun</option>
                  <option value="2 tahun - 3 tahun">2 tahun - 3 tahun</option>
                  <option value="3 tahun++">3 tahun++</option>
                </Select>
              </Box>
              <Box>
                <Text>time</Text>
                <RadioGroup
                  onChange={(e) => setData({ ...data, time: e })}
                  // value={value}
                >
                  <Stack direction="row">
                    <Radio value="Full Time">Full Time</Radio>
                    <Radio value="Part Time">Part Time</Radio>
                    <Radio value="Full & Part Time">Full & Part Time</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </SimpleGrid>
          </Box>
          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <Text>Job Type</Text>
            <Select
              placeholder="Type"
              bgColor="white"
              onChange={(e) => handleType(e.target.value)}
            >
              {jobType.map((x, i) => (
                <option key={i} value={x.name}>
                  {x.name}
                </option>
              ))}
              <option value="add new type">Add new type</option>
            </Select>
            {add == true ? (
              <>
                <Text>Job Type</Text>
                <Input
                  placeholder="Type"
                  bgColor="white"
                  onChange={(e) =>
                    setData({ ...data, job_type: e.target.value })
                  }
                />
              </>
            ) : null}
          </Box>

          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <Heading fontSize="xl">Job Description</Heading>
            {val.map((x, i) => (
              <Input
                key={i}
                type="text"
                bg="white"
                placeholder={`JOBDESK ${i + 1}`}
                onChange={(e) => handleChangeJobDesc(e, i)}
              />
            ))}

            <Center>
              <Button bg="brand.100" onClick={() => handleAddJobDesc()}>
                Tambah Job Description
              </Button>
            </Center>
          </Box>

          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <Heading fontSize="xl">Qualifications</Heading>

            {val2.map((x, i) => (
              <Input
                key={i}
                type="text"
                bg="white"
                placeholder={`CRITERIA ${i + 1}`}
                onChange={(e) => handleChangeCriteria(e, i)}
              />
            ))}

            <Center>
              <Button bg="brand.100" onClick={() => handleAddCriteria()}>
                Tambah kualifikasi
              </Button>
            </Center>
          </Box>

          <Box m="2">
            <Button
              width="full"
              bgColor="brand.100"
              onClick={() => handleSave()}
              disabled={loading ? true : false}
            >
              Publish {loading ? <Spinner mx={5} /> : null}
            </Button>
          </Box>
        </Box>

        {/* ------------------------------------------------------------------ THIS IS PREVIEW */}

        <Box m="2" p="2" bg="gray.100" borderRadius="md">
          <HStack>
            <Image boxSize="100px" src={companyData.image} />
            <Stack>
              <Heading>{data?.position ? data.position : null}</Heading>
              <Text fontSize={12} color="blackAlpha.600">
                Berakhir pada : {data.expiry}
              </Text>
            </Stack>
          </HStack>
          <Box m="3">
            <Heading fontSize="lg">Deskripsi Pekerjaan</Heading>
            <Text>{data?.description}</Text>
          </Box>
          <Box>
            <SimpleGrid columns="2">
              <List spacing={1}>
                <ListItem>
                  <ListIcon as={InfoIcon} color="blue.500" />
                  {data.range_salary_high
                    ? `Rp${new Intl.NumberFormat("en-ID", {
                        maximumSignificantDigits: 3,
                      }).format(
                        data.range_salary_low
                      )} - Rp${new Intl.NumberFormat("en-ID", {
                        maximumSignificantDigits: 3,
                      }).format(data.range_salary_high)} `
                    : "Negotiable"}
                </ListItem>
                <ListItem>
                  <ListIcon as={TimeIcon} color="blue.500" />
                  {data?.time ? data.time : <></>}
                </ListItem>
                <ListItem>
                  <ListIcon as={CalendarIcon} color="blue.500" />
                  {data?.experience ? data.experience : <></>}
                </ListItem>
              </List>

              <List spacing={1}>
                <ListItem>
                  <ListIcon as={InfoIcon} color="blue.500" />
                  {data?.job_location ? data.job_location : <></>}
                </ListItem>
                {/* <ListItem>
                  <ListIcon as={TimeIcon} color="blue.500" />
                  {data?.time?data.time:<></>}
                </ListItem>
                <ListItem>
                  <ListIcon as={CalendarIcon} color="blue.500" />
                  {data?.experience?data.experience:<></>}
                </ListItem> */}
              </List>
            </SimpleGrid>
          </Box>
          <Divider m="2" width="80%" />

          <Box>
            <Heading fontSize="lg">Job Description</Heading>
            <Text p="2">{data?.job_responsibility}</Text>

            <List spacing={1}>
              {data?.job_descriptions?.length > 0
                ? data.job_descriptions.map((x, i) => (
                    <ListItem key={i}>
                      <ListIcon as={CheckIcon} color="green.500" />
                      {x}
                    </ListItem>
                  ))
                : null}
            </List>
          </Box>
          <Box>
            <Divider m="2" width="80%" />
            <Heading fontSize="lg">Qualification</Heading>
            <List spacing={1}>
              {data?.job_criteria?.length > 0
                ? data.job_criteria.map((x, i) => (
                    <ListItem key={i}>
                      <ListIcon as={CheckIcon} color="green.500" />
                      {x}
                    </ListItem>
                  ))
                : null}
            </List>
          </Box>
          <Center>
            <HStack p="2">
              <Button bgColor="brand.100">Daftar</Button>
              <Button bgColor="brand.100">Simpan</Button>
              <Button bgColor="brand.100">Share</Button>
            </HStack>
          </Center>
        </Box>
      </SimpleGrid>
    </>
  );
}

export default RolesAddPage;
