import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import RecruitmentFunnels from "./RecruitmentFunnels";

function RecruitmentBox({ data }) {
  const {
    position,
    company,
    status,
    totalViews,
    totalSubmission,
    postedBy,
    seenBy,
    companyId,
    expiry,
  } = data.data;

  const navigate = useNavigate();

  const handleDeactivate = async () => {
    console.log(data.id);
    Swal.fire({
      title: `Nonaktifkan Iklan ${position}?`,
      text: `${company}`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Nonaktifkan",
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <Box p="2" bgColor="white" borderRadius="md" mx="100px">
      <Flex flexDirection={{base:'column', lg:'row'}} justifyContent="space-between"> 
        <Box>
          <HStack>
            <Heading fontSize="md">{position}</Heading>
            <Badge bg={status ? "green" : "red"} color="white">
              {status ? "Active" : "Inactive"}
            </Badge>
          </HStack>
          <Heading fontSize="sm" color="gray.600">
            {companyId}
          </Heading>

          <Text fontSize={12}>Diposting oleh {postedBy}</Text>
          <Text fontSize={12}>Masa tayang akan berakhir pada{expiry}</Text>
        </Box>
        <Spacer />
        <Spacer />
        <Spacer />
        <RecruitmentFunnels data={data.data} seenBy={seenBy} />
        {/* <Text fontSize={6}>{JSON.stringify(data.data)}</Text> */}
        <Box borderLeft="1px" p="2" width="36">
          <Text fontSize="xs">
            Total dilihat {seenBy ? seenBy.length : totalViews}
          </Text>
          <Text fontSize="xs">Total lamaran {totalSubmission}</Text>
        </Box>
      </Flex>
      <Divider m="2" />
      <HStack>
        <Link style={{fontSize:12}}  to={`/jobs-admin/roles/view/${data.id}`}>Lihat</Link>
        <Link style={{fontSize:12}} to="/jobs-admin/roles">Ubah</Link>
        <Link style={{fontSize:12}} onClick={handleDeactivate}>Nonaktifkan</Link>
        {/* <Link style={{fontSize:12}} to="/jobs-admin/dashboard">Tayangkan kembali</Link>
        <Link style={{fontSize:12}} to="/jobs-admin/dashboard">Berkolaborasi</Link>
        <Link style={{fontSize:12}} to="/jobs-admin/dashboard">Cari Bakat</Link> */}
        <Spacer />
        <Button
          bgColor="brand.100"
          size='xs'
          fontSize={10}
          onClick={() => window.open(`preview-roles/${data.id}`)}
        >
          Lihat Preview
        </Button>
      </HStack>
    </Box>
  );
}

export default RecruitmentBox;
