import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  Spacer,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { doc, getDoc } from "firebase/firestore";
import { authFirebase, db } from "../../Config/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../AppComponents/AppButton";

const AppBarProfile = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const x = localStorage.getItem("userData");
  const userData = x ? JSON.parse(x) : "null";

  const getUser = async () => {
    try {
      const docRef = doc(db, "users", authFirebase.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap) {
        setData(docSnap.data());
        console.log(docSnap.data());
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLogout = () => {
    setLoading(true);
    localStorage.clear();
    // console.log("in handle logout", data.uid, typeof userId);
    try {
      authFirebase.signOut();
      setLoading(false);
      navigate('/')
    } catch (error) {
      console.log(error.message, "error di handle logout");
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Flex 
      // minH={"100vh"} 
      justify={"center"} position="relative" bg="gray.100">
        <Stack
          spacing={4}
          w={"full"}
          maxW="70%"
          p={6}
          my={12}
          bg="white"
          direction={{ base: "column", lg: "column" }}
        >
          <Center>
            <Avatar
              m={5}
              size="xl"
              name={userData.name}
              src={userData.image}
              bg="teal.500"
            />

            <AppButton
              title="Logout"
              bg="red.400"
              color="white"
              onClick={handleLogout}
              loading={loading}
            />
            <AppButton
              title="Edit Profile"
              bg="gray.200"
              color="gray.600"
              onClick={() => navigate(`/${userData.role}/profile-edit`)}
              loading={loading}
            />

            {/* <Button
              bg={"gray"}
              color={"white"}
              _hover={{
                bg: "green",
              }}
              m={5}
              onClick={() => navigate(`/${userData.role}/profile-edit`)}
              disabled={loading ? true : false}
            >
              Edit Profile {loading ? <Spinner mx={5}/> : null}
            </Button> */}

            {userData.role === "employer" ? (
              <Button
                bg={"gray"}
                color={"white"}
                _hover={{
                  bg: "green",
                }}
                m={5}
                onClick={() => navigate("/employer/company-profile")}
                disabled={loading ? true : false}
              >
                Edit Company Profile {loading ? <Spinner /> : null}
              </Button>
            ) : null}
          </Center>
          <SimpleGrid columns={2} align="center" spacingY={10}>
            <Heading size="md" color="gray.700">
              Name
            </Heading>
            <Heading size="md" color="gray.700">
              {data.name}
            </Heading>
            <Heading size="md" color="gray.700">
              Email address
            </Heading>
            <Heading size="md" color="gray.700">
              {data.email}
            </Heading>
            <Heading size="md" color="gray.700">
              Role
            </Heading>
            <Heading size="md" color="gray.700">
              {data.role}
            </Heading>
            {data.role == "employer" ? (
              <>
                <Heading size="md" color="gray.700">
                  Company
                </Heading>
                <Heading size="md" color="gray.700">
                  {data.company}
                </Heading>
              </>
            ) : null}
          </SimpleGrid>
        </Stack>
      </Flex>
    </>
  );
};

export default AppBarProfile;
