import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppBarHeader from "../../../Components/AppBars/AppBarHeaderEmployer";
import RecruitmentFunnels from "../../../Components/AppComponents/RecruitmentFunnels";
import { RiWhatsappLine, RiWhatsappFill } from "react-icons/ri";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../../Config/firebase";
import AppBarCandidateView from "../../../Components/AppBars/AppBarCandidateView";
import { AppBarHeaderNew } from "../../../Components/AppBars/AppBarHeaderNew";

function CandidateViewPage() {
  return (
    <>
      {/* <AppBarHeaderNew /> */}
      <AppBarCandidateView />
    </>
  );
}

export default CandidateViewPage;
