import { Heading } from '@chakra-ui/react'
import React from 'react'
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation'
import AppBarTeams from '../Components/AppBars/AppBarTeams'

const TeamsPage = () => {
  return (
    <>
        <AppBarSideNavigation>
            <AppBarTeams />
        </AppBarSideNavigation>
    </>
  )
}

export default TeamsPage
