import React, { ReactNode, useEffect, useState } from 'react';
import {
	IconButton,
	Avatar,
	Box,
	CloseButton,
	Flex,
	HStack,
	VStack,
	Icon,
	useColorModeValue,
	Link,
	Drawer,
	DrawerContent,
	Text,
	useDisclosure,
	BoxProps,
	FlexProps,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Heading,
	Image,
	Select,
	Spinner,
	Button,
	useColorMode,
} from '@chakra-ui/react';
import {
	FiHome,
	FiTrendingUp,
	FiCompass,
	FiStar,
	FiSettings,
	FiMenu,
	FiBell,
	FiChevronDown,
} from 'react-icons/fi';
import {
	BsFillCameraVideoFill
} from 'react-icons/bs';
import {
	RiTeamFill
} from 'react-icons/ri';
import {
	MdOutlineWorkOutline
} from 'react-icons/md';
import {
	GrDocumentTest
} from 'react-icons/gr';
import {
	GiCardboardBox
} from 'react-icons/gi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { useAppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { authFirebase } from '../../Config/firebase';

export default function SidebarWithHeader({ children }) {
	const [width, setWidth] = useState(0);

	const { userData } = useAppContext();

	useEffect(() => {
		window.addEventListener('resize', () => setWidth(window.innerWidth));

		return (
			window.removeEventListener('resize', () => setWidth(window.innerWidth))
		)
	}, []);


	const navigate = useNavigate();

	const isMobile = width <= 768;

	const { isOpen, onOpen, onClose } = useDisclosure();


	const handleLogout = () => {
		localStorage.clear();
		// console.log("in handle logout", data.uid, typeof userId);
		try {
			authFirebase.signOut();
			navigate('/')
		} catch (error) {
			console.log(error.message, "error di handle logout");
		}
	};

	return (
		<Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
			<SidebarContent
				onClose={() => onClose}
				display={{ base: 'none', md: 'block' }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				{/* <DrawerContent>
					<SidebarContent onClose={onClose} userData={userData} />
				</DrawerContent> */}
			</Drawer>
			{/* mobilenav */}
			<MobileNav handleLogout={handleLogout} onOpen={onOpen} isMobile={isMobile} />
			<Box ml={{ base: 0, md: 60 }} p="4">
				{children}
			</Box>
		</Box>
	);
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
}


const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
	const { companies, userData } = useAppContext();
	const [selected, setSelected] = useState('');

	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue('#ffd600', 'gray.900')}
			borderRight="1px"
			borderRightColor={useColorModeValue('gray.200', 'gray.700')}
			w={{ base: 'full', md: 60 }}
			pos="fixed"
			h="full"
			{...rest}>
			<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
				<Image
					src={require("../../Image/logo_intra_black.png")}
					maxW="150px"
					py={1}
				/>
				<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			</Flex>
			<Box p="4" mx="2">
				<Menu>
					<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
						Company
					</MenuButton>
					{companies ? (
						<>
							<MenuList>
								{companies.map((company, i) => (
									<MenuItem key={i} value={i} onClick={() => console.log(company.image)}><Avatar src={company.image} borderWidth={2} m={2} /><Text>{company.name}</Text></MenuItem>
								))}
							</MenuList>
						</>
					) : (
						<Spinner />
					)}
				</Menu>
			</Box>
			{/* {LinkItems.map((link) => (
				<NavItem navigation={link.navigation} key={link.name} icon={link.icon} fontWeight={600}>
					{link.name}
				</NavItem>
			))} */}
			<NavItem navigation='/talent/dashboard' icon={FiHome} fontWeight={600}>
				Dashboard
			</NavItem>
			<NavItem navigation='/talent/trainings/pt edrus pasar digital' icon={BsFillCameraVideoFill} fontWeight={600}>
				Trainings
			</NavItem>
			<NavItem navigation='/talent/jobs' icon={MdOutlineWorkOutline} fontWeight={600}>
				Jobs
			</NavItem>
			{userData?.level?.includes('product_owner') || userData?.level?.includes('product_manager') || userData?.level?.includes('hr') ?
				<NavItem navigation='/teams' icon={RiTeamFill} fontWeight={600}>
					Teams
				</NavItem>
				: null}

			{userData?.level?.includes('product_owner') || userData?.level?.includes('product_manager') || userData?.level?.includes('hr') ?
				<NavItem navigation='/training-admin' icon={FiStar} fontWeight={600}>
					Training Admin
				</NavItem>
				: null}

			{userData?.level?.includes('hr') ?
				<NavItem navigation='/jobs-admin' icon={FiSettings} fontWeight={600}>
					Jobs Admin
				</NavItem>
				: null}

			{userData?.level?.includes('hr') ?
				<NavItem navigation='/assessments' icon={GrDocumentTest} fontWeight={600}>
					Assessments
				</NavItem>
				: null}
			{userData?.level?.includes('hr') ?
				<NavItem navigation='/thankyou-box-responses' icon={GiCardboardBox} fontWeight={600}>
					Thankyou Box
				</NavItem>
				: null}

		</Box>
	);
};

interface NavItemProps extends FlexProps {
	icon: IconType;
	children: ReactText;
	navigation: string;
}
const NavItem = ({ icon, children, navigation, ...rest }: NavItemProps) => {
	const navigate = useNavigate();
	return (
		<Link onClick={() => navigate(navigation)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
			<Flex
				align="center"
				p="4"
				mx="4"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				_hover={{
					bg: 'blackAlpha.400',
					color: 'white',
				}}
				{...rest}>
				{icon && (
					<Icon
						mr="4"
						fontSize="16"
						_groupHover={{
							color: 'white',
						}}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</Link>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
	userData: () => object;
}
const MobileNav = ({ onOpen, userData, isMobile, handleLogout, ...rest }: MobileProps) => {
	const { colorMode, toggleColorMode } = useColorMode();

	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 4 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue('white', 'gray.900')}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			justifyContent={{ base: 'space-between', md: 'flex-end' }}
			{...rest}>
			<IconButton
				display={{ base: 'flex', md: 'none' }}
				onClick={onOpen}
				variant="outline"
				aria-label="open menu"
				icon={<FiMenu />}
			/>
	<Button onClick={toggleColorMode}>
					{colorMode === 'dark' ? 'Dark' : 'Light'} Mode
				</Button>
			{isMobile ?
				<>
					<Image
						src={require("../../Image/logo_intra_black.png")}
						maxW="150px"
						py={1}
					/>
				</>
				: null}

			<HStack spacing={{ base: '0', md: '6' }}>
				<IconButton
					size="lg"
					variant="ghost"
					aria-label="open menu"
					icon={<FiBell />}
				/>
				<Flex alignItems={'center'}>
					<Menu>
						<MenuButton
							py={2}
							transition="all 0.3s"
							_focus={{ boxShadow: 'none' }}>
							<HStack>
								<Avatar
									size={'sm'}

									src={
										userData?.image
									}
								/>
								<VStack
									display={{ base: 'none', md: 'flex' }}
									alignItems="flex-start"
									spacing="1px"
									ml="2">
									<Text fontSize="sm">{userData?.name && userData?.name}</Text>
									<Text fontSize="xs" color="gray.600">
										{userData?.role && userData?.role}
									</Text>
								</VStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
						<MenuList
							bg={useColorModeValue('white', 'gray.900')}
							borderColor={useColorModeValue('gray.200', 'gray.700')}>
							<MenuItem>Profile</MenuItem>
							<MenuItem>Settings</MenuItem>
							<MenuItem>Billing</MenuItem>
							<MenuDivider />
							<MenuItem onClick={handleLogout}>Sign out</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};