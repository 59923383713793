import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Flex,
  Center,
  SimpleGrid,
  Stack,
  Text,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardBody,
  Divider,
} from "@chakra-ui/react";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import AppBarHeader from "../../Components/AppBars/AppBarHeaderEmployer";
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import { db } from "../../Config/firebase";

function TalentPage() {
  const [talents, setTalents] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const getTalents = async () => {
    const q = query(collection(db, "company", userData.company, "employee"));
    let arr = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      arr.push({ data: doc.data(), id: doc.id });

      // getDocs(query(collection(db, "roles", doc.id, "candidates"))).then(
      //   (res) => {
      //     let size = res.size;
      //     arr.filter((x) => x.id == doc.id)[0].length = size;
      //   }
      // );
    });
    setTalents(arr);
  };

  useEffect(() => {
    getTalents();
  }, []);

  const TalentComponent = (data) => {
    const { name, WD, VAK, bazi, DISC, HD, position } = data.data.data;
    return (
      // <Box
      //   // p="2"
      //   // m="2"
      //   // bgColor="white"
      //   // borderRadius="md"
      //   borderBottomWidth="20"
      //   borderColor="teal"
      // >
      //   <HStack>
      //     <Image
      //       boxSize="100px"
      //       src="https://images.glints.com/unsafe/160x0/glints-dashboard.s3.amazonaws.com/company-logo/c314e745b70fb702232d21d4b45722e3.jpg"
      //       alt="Dan Abramov"
      //     />
      //     <Box>
      //       <Heading fontSize="md">{name}</Heading>
      //       <Text>{position}</Text>
      //     </Box>
      //   </HStack>
      //   <Text>Type</Text>
      //   <SimpleGrid columns={2}>
      //     <Text>DISC: {DISC}</Text>
      //     <Text>WD: {WD}</Text>
      //     <Text>HD: {HD}</Text>
      //     <Text>Bazi: {bazi}</Text>
      //     <Text>VAK: {VAK}</Text>
      //   </SimpleGrid>
      // </Box>
      <Card maxW="md">
        <CardHeader>
          <Flex spacing="4">
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              {/* <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />

              <Box>
                <Heading size="sm">Segun Adebayo</Heading>
                <Text>Creator, Chakra UI</Text>
              </Box> */}
              <Image
                boxSize="100px"
                src="https://images.glints.com/unsafe/160x0/glints-dashboard.s3.amazonaws.com/company-logo/c314e745b70fb702232d21d4b45722e3.jpg"
                alt="Dan Abramov"
              />
              <Box>
                <Heading fontSize="md">{name}</Heading>
                <Text>{position}</Text>
              </Box>
            </Flex>
            <IconButton
              variant="ghost"
              colorScheme="gray"
              aria-label="See menu"
              icon={<BsThreeDotsVertical />}
            />
          </Flex>
        </CardHeader>
        <Divider />
        <CardBody>
          <SimpleGrid columns={2}>
            <Text>DISC: {DISC}</Text>
            <Text>WD: {WD}</Text>
            <Text>HD: {HD}</Text>
            <Text>Bazi: {bazi}</Text>
            <Text>VAK: {VAK}</Text>
          </SimpleGrid>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      {/* <AppBarHeaderNew /> */}
      <Center>
        <Flex
          alignContent="center"
          flexDir="column"
          w={{ base: "full", md: "full", lg: "full" }}
          bgColor="gray.200"
          minH="100vw"
        >
          <Box p="2" m="2" borderRadius="md">
            <Heading>Talent</Heading>
          </Box>
          <HStack p="2" m="2" bgColor="gray.50" borderRadius="md">
            <Text>Filter</Text>
            <Box>
              <Text>VAK</Text>
            </Box>
            <Box>
              <Text>DISC</Text>
            </Box>
            <Box>
              <Text>Wealth Dynamic</Text>
            </Box>
            <Box>
              <Text>Human Design</Text>
            </Box>
            <Box>
              <Text>Bazi</Text>
            </Box>
          </HStack>
          <SimpleGrid columns={3}>
            {talents.length > 0
              ? talents.map((talent, index) => (
                  <TalentComponent key={index} data={talent} />
                ))
              : null}
          </SimpleGrid>
        </Flex>
      </Center>
    </>
  );
}

export default TalentPage;
