import React from 'react'
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  OrderedList,
  Center,
  Button,
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';

const AppBarEditTraining = () => {
  let {state} = useLocation();
  console.log(state.training.data.videoList, "state.training.data.name nih boss")
  return (
    <>
            <Center>
              <Stack spacing={8} width='80%'>
                    <FormControl >
                      <FormLabel>Training Name</FormLabel>
                      <Input
                        placeholder="name"
                        id="name"
                        defaultValue={state?.training?.data?.name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Thumbnail URL</FormLabel>
                      <Input
                        placeholder="url"
                        id="name"
                        defaultValue={state?.training?.data?.thumbnail}
                      />
                    </FormControl>
                    <OrderedList>
                        {state?.training?.data?.videoList?.length > 0 ?
                          state?.training?.data?.videoList.map((video, i) => (
                                  <>
                                    <Box p={2} borderWidth={2} key={i} bg='gray.100'>
                                      <FormControl>
                                        <Flex>
                                            <FormLabel fontSize="xs" flex={1}>Video_Title</FormLabel>
                                            <Input
                                              bg='white'
                                              placeholder="Content Creation"
                                              id="title"
                                              size='xs'
                                              defaultValue={video?.title}
                                            />
                                        </Flex>
                                      </FormControl>
                                      <FormControl display='flex' flex={5}>
                                        <FormLabel fontSize="xs">Video_url</FormLabel>
                                        <Input
                                          bg='white'
                                          size='xs'
                                          placeholder="Content Creation"
                                          id="id"
                                          defaultValue={video?.url}
                                        />
                                      </FormControl>
                                    </Box>
                                  </>
                          ))
                        : <Heading>Kuda</Heading>}
                    </OrderedList>
                    <Button bg='red.500' color='white'>Save Changes</Button>
              </Stack>
            </Center>
    </>
  )
}

export default AppBarEditTraining
