import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import React, { useState, useCallback, useEffect } from "react";
import AppBarHeaderEmployee from "./AppBarHeaderEmployee";
import { useDropzone } from "react-dropzone";
import { UploadBlob, UploadFile } from "../../Utils/Upload";
import { authFirebase, db } from "../../Config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const AppBarEditEmployerProfile = () => {
  const toast = useToast();
  const [data, setData] = useState({});
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const x = localStorage.getItem("userData");
  const userData = x ? JSON.parse(x) : "no data";

  const getData = async () => {
    const docRef = doc(db, "users", authFirebase.currentUser.uid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap) console.log(docSnap.data());
    } catch (error) {
      console.log(error.message, "error in ger data");
    }
  };

  useEffect(() => {
    // getData();
  }, []);

  const forms = [
    {
      label: "Nama Lengkap (sesuai KTP)",
      placeholder: "Reinhart Samuel",
      name: "name",
    },
    {
      label: "Nomor Telepon / WA aktif",
      placeholder: "0878******",
      name: "phone",
    },
    { label: "Email", placeholder: "mail@example.com", name: "email" },
    {
      label: "Alamat",
      placeholder: "Cibubur, Bogor, Jawa Barat",
      name: "address",
    },
    // {
    //   label: "Lama Pengalaman",
    //   placeholder: "0 tahun",
    //   name: "experience",
    // },
  ];

  const handleData = (value, key) => {
    const newData = data;
    newData[key] = value;
    setData(newData);
  };

  const handleSave = async () => {
    setLoading(true);
    // console.log({ ...data, image: preview });
    try {
      if (preview) {
        const docSnap = await setDoc(
          doc(db, "users", authFirebase.currentUser.uid),
          {
            ...data,
            image: preview,
          },
          { merge: true }
        );
      } else if (!preview) {
        const docSnap = await setDoc(
          doc(db, "users", authFirebase.currentUser.uid),
          data,
          { merge: true }
        );
      }

      toast({
        title: "Update berhasil",
        description: "Silakan logout dan login kembali.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error.message, "error in saving to user db");
      toast({
        title: "Terjadi Kesalahan",
        description: `${error.message}, error saving `,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const handleSaveImage = async () => {
    //handleSaveImage only saves image to storage, but the url still needs to be posted to firebase firestore
    setLoading(true);
    try {
      const uploaded = await UploadBlob(
        image,
        "users",
        userData.name,
        "profile"
      );
      if (uploaded) {
        setPreview(uploaded.url);
        console.log("PREVIEW", preview);
      }
    } catch (error) {
      console.log(error.message, "error on uploading image");
    }
    setLoading(false);
  };
  return (
    <>
      {/* <Button onClick={() => console.log(preview)}>go</Button> */}
      <Stack
        align="center"
        justify="center"
        maxW={{ base: "95%", md: "90%", lg: "70%", xl: "65%" }}
      >
        <Heading>Detail Informasi</Heading>
        <Text>Mohon lengkapi informasi di bawah ini dengan benar:</Text>
        <HStack w="full">
          <Heading size="xs">Foto Profil</Heading>

          <Box
            w="200px"
            h="100px"
            backgroundColor="gray.100"
            borderColor="gray.200"
            position="relative"
          >
            <Image src={preview ? preview : userData.image} />
          </Box>
          <Input type="file" onChange={(e) => setImage(e.target.files[0])} />
          {image ? (
            <Button onClick={() => handleSaveImage()}>
              Save{loading ? <Spinner mx={5} /> : null}
            </Button>
          ) : null}
        </HStack>

        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        <Heading></Heading>
        {forms &&
          forms.map((form, index) => (
            <FormControl key={index}>
              <FormLabel>{form.label}</FormLabel>
              <Input
                placeholder={form.placeholder}
                defaultValue={userData[form.name]}
                id={form.name}
                onChange={(e) => handleData(e.target.value, e.target.id)}
              />
            </FormControl>
          ))}

        <HStack spacing={5}>
          <Button
            disabled={loading ? true : false}
            onClick={() => handleSave()}
          >
            Save {loading ? <Spinner mx={5} /> : null}
          </Button>
          <Button>Cancel</Button>
        </HStack>
      </Stack>
    </>
  );
};

export default AppBarEditEmployerProfile;
