import {
	Box,
	Button,
	Heading,
	HStack,
	Spacer,
	Stack,
	Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBarHeader from "../../Components/AppBars/AppBarHeaderEmployer";
import RecruitmentBox from "../../Components/AppComponents/RecruitmentBox";
import {
	collection,
	query,
	where,
	getDocs,
	getDoc,
	doc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import AppBarSideNavigation from '../../Components/AppBars/AppBarSideNavigation';

function RolesPage() {
	const navigate = useNavigate();
	const { availableRoles } = useAppContext();

	return (
		<>
			{/* <Stack bg="gray.100" w="full" h="100vh"> */}
			{/* <AppBarHeaderNew /> */}
			<AppBarSideNavigation>
				<Stack
					alignSelf="center"
					// w={{ base: "full", md: "full", lg: "90vw" }}
					bg="gray.100"
					overflowY="scroll"
				>
					<Box p="2" bgColor="white" borderRadius="md">
						<HStack>
							<Heading>Roles</Heading>
							<Spacer />
							<Button
								bgColor="brand .100"
								onClick={() => navigate("/roles/add")}
							>
								Tambah Role
							</Button>
						</HStack>
					</Box>
					{/* {activeOpenPosition.map((x, i) => (
						<RecruitmentBox key={i} data={x} />
									))} */}
					{availableRoles.length > 0 ? (
						availableRoles.map((item, index) => (
							<RecruitmentBox data={item} key={index} />
						))
					) : (
						<Heading textAlign="center" size="sm">
							Belum ada roles
						</Heading>
					)}
				</Stack>
			</AppBarSideNavigation>
			{/* </Stack> */}
		</>
	);
}

export default RolesPage;
