import React from 'react'
import AppBarJobsView from '../../../Components/AppBars/AppBarJobsView';
import AppBarSideNavigation from '../../../Components/AppBars/AppBarSideNavigation';

const JobsViewPage = () => {
  return (
    <>
      <AppBarSideNavigation>
        <AppBarJobsView />
      </AppBarSideNavigation>
    </>
  )
}

export default JobsViewPage
