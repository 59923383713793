import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  Divider,
  Spacer,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import React, { useState, useCallback, useEffect } from "react";
import AppBarHeaderEmployee from "./AppBarHeaderEmployee";
import { useDropzone } from "react-dropzone";
import { UploadBlob, UploadFile } from "../../Utils/Upload";
import { authFirebase, db } from "../../Config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@chakra-ui/icons";

const AppBarEditEmployeeProfile = () => {
  const toast = useToast();
  const [data, setData] = useState({});
  const [dataExperience, setDataExperience] = useState({});
  const [experienceList, setExperienceList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [val, setVal] = useState([]);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const x = localStorage.getItem("userData");
  const userData = x ? JSON.parse(x) : "no data";

  const forms = [
    {
      label: "Nama Lengkap (sesuai KTP)",
      placeholder: "Reinhart Samuel",
      name: "name",
    },
    {
      label: "Nomor Telepon / WA aktif",
      placeholder: "0878******",
      name: "phone",
    },
    { label: "Email", placeholder: "mail@example.com", name: "email" },
    {
      label: "Alamat",
      placeholder: "Cibubur, Bogor, Jawa Barat",
      name: "address",
    },
    {
      label: "Lama Pengalaman",
      placeholder: "0 tahun",
      name: "experience",
    },
  ];

  const handleAddForms = (type) => {
    if (type == "experience") {
      setExperienceList([...experienceList, {}]);
    } else if (type == "education") {
      setEducationList([...educationList, {}]);
    }
  };

  const handleExperienceChange = (index, event) => {
    const newExperienceList = [...experienceList];
    newExperienceList[index][event.target.id] = event.target.value;
    setExperienceList(newExperienceList);
  };

  const handleEducationChange = (index, event) => {
    const newEducationList = [...educationList];
    newEducationList[index][event.target.id] = event.target.value;
    setEducationList(newEducationList);
    console.log(newEducationList);
  };

  const getData = async () => {
    const docRef = doc(db, "users", authFirebase.currentUser.uid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap) console.log(docSnap.data());
    } catch (error) {
      console.log(error.message, "error in ger data");
    }
  };

  useEffect(() => {
    // getData();
  }, []);

  const handleData = (value, key) => {
    const newData = data;
    newData[key] = value;
    setData(newData);
  };
  const handleSave = async () => {
    setLoading(true);
    // console.log({ ...data, image: preview });
    try {
      if (preview && experienceList && educationList) {
        const docSnap = await setDoc(
          doc(db, "users", authFirebase.currentUser.uid),
          {
            ...data,
            experiences: experienceList,
            education: educationList,
            image: preview,
          },
          { merge: true }
        );
      } else if (!preview && experienceList && educationList) {
        const docSnap = await setDoc(
          doc(db, "users", authFirebase.currentUser.uid),
          {
            ...data,
            experiences: experienceList,
            education: educationList,
          },
          { merge: true }
        );
      } else if (preview && !experienceList && educationList) {
        const docSnap = await setDoc(
          doc(db, "users", authFirebase.currentUser.uid),
          {
            ...data,
            experiences: experienceList,
            education: educationList,
          },
          { merge: true }
        );
      }

      toast({
        title: "Update berhasil",
        description: "Silakan kembali ke halaman sebelumnya.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error.message, "error in saving to user db");
      toast({
        title: "Terjadi Kesalahan",
        description: `${error.message}, error saving `,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const handleSaveImage = async () => {
    //handleSaveImage only saves imfage to storage, but the url still needs to be posted to firebase firestore
    setLoading(true);
    try {
      const uploaded = await UploadBlob(
        image,
        "users",
        userData.name,
        "profile"
      );
      if (uploaded) {
        setPreview(uploaded.url);
        console.log("PREVIEW", preview);
      }
    } catch (error) {
      console.log(error.message, "error on uploading image");
    }
    setLoading(false);
  };
  return (
    <>
      {/* <Button onClick={() => console.log(preview)}>go</Button> */}
      <Stack
        align="center"
        justify="center"
        maxW={{ base: "95%", md: "90%", lg: "70%", xl: "65%" }}
      >
        <Link to="/employee/profile">
          <ArrowLeftIcon />
        </Link>
        <Heading>Detail Informasi</Heading>
        <Text>Mohon lengkapi informasi di bawah ini dengan benar:</Text>
        <Box padding="100">
          <HStack w="full">
            <Heading size="xs">Foto Profil</Heading>
            <Box
              w="200px"
              h="100px"
              backgroundColor="gray.100"
              borderColor="gray.200"
              position="relative"
            >
              <Image src={preview ? preview : userData.image} />
            </Box>
            <Input type="file" onChange={(e) => setImage(e.target.files[0])} />
            {image ? (
              <Button onClick={() => handleSaveImage()}>
                Save{loading ? <Spinner mx={5} /> : null}
              </Button>
            ) : null}
          </HStack>
        </Box>

        {forms &&
          forms.map((form, index) => (
            <FormControl key={index}>
              <FormLabel>{form.label}</FormLabel>
              <Input
                placeholder={form.placeholder}
                defaultValue={userData[form.name]}
                id={form.name}
                onChange={(e) => handleData(e.target.value, e.target.id)}
              />
            </FormControl>
          ))}

        <FormControl>
          <FormLabel>Gender</FormLabel>
          <Select
            id="gender"
            size="sm"
            onChange={(e) => handleData(e.target.value, e.target.id)}
          >
            <option value="male">Laki-laki</option>
            <option value="female">Perempuan</option>
            <option value="others">Lainnya</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Bio</FormLabel>
          <Textarea
            size="sm"
            defaultValue={userData.bio}
            placeholder="Deskripsikan dirimu dengan singkat dan jelas"
            onChange={(e) => handleData(e.target.value, e.target.id)}
            id="bio"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Sertifikasi</FormLabel>
          {/* <Textarea placeholder="Deskripsikan dirimu dengan singkat dan jelas" /> */}
          <Input
            defaultValue={userData.bio}
            placeholder="dicoding"
            id="certification"
            onChange={(e) => handleData(e.target.value, e.target.id)}
          />
        </FormControl>
        <Divider borderWidth={2} borderColor="blackAlpha.800" />
        <VStack>
          <Heading>Pengalaman</Heading>
          <Text>Isi informasi pengalaman kerja atau magang di bawah ini</Text>
        </VStack>
        <Button bg="brand.100" onClick={() => handleAddForms("experience")}>
          Tambah Pengalaman +
        </Button>

        {experienceList.map((experience, index) => (
          <Box key={index} w="full" p={2} borderRadius="md" borderWidth={2}>
            <FormControl isRequired mt={5}>
              <FormLabel>Posisi</FormLabel>
              <Input
                size="sm"
                placeholder="Posisi (e.g Account Excecutive)"
                id="position"
                onChange={(e) => handleExperienceChange(index, e)}
              />
            </FormControl>
            <FormControl isRequired mt={5}>
              <FormLabel>Company</FormLabel>
              <Input
                size="sm"
                placeholder="Nama perusahaan (e.g PT Sumber Jaya)"
                id="company"
                onChange={(e) => handleExperienceChange(index, e)}
              />
            </FormControl>

            <SimpleGrid mt={2} columns={2} spacing={2}>
              <FormControl isRequired>
                <FormLabel>Start date</FormLabel>
                <Input
                  size="sm"
                  placeholder="Start date"
                  id="startDate"
                  type="date"
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>End date</FormLabel>
                <Input
                  size="sm"
                  placeholder="End Date"
                  id="endDate"
                  type="date"
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              </FormControl>

              <FormControl isRequired mt={5}>
                <FormLabel>Spesialisasi (pisahkan dengan koma)</FormLabel>
                <Input
                  size="sm"
                  placeholder="Specialization (e.g Ms. Excel, Autocad, Video Editing, etc)"
                  id="specialization"
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              </FormControl>

              <FormControl isRequired mt={5}>
                <FormLabel>Level</FormLabel>
                <Select
                  size="sm"
                  id="level"
                  onChange={(e) => handleExperienceChange(index, e)}
                >
                  <option value="">Pilih Level</option>
                  <option value="junior">Junior</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="senior">Senior</option>
                </Select>
              </FormControl>

              <FormControl isRequired mt={5}>
                <FormLabel>Industri</FormLabel>
                <Input
                  size="sm"
                  placeholder="Industri (e.g Costumer goods, Mining, Advertising, Tax & Finance, etc."
                  id="industry"
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              </FormControl>
              <FormControl isRequired mt={5}>
                <FormLabel>Salary</FormLabel>
                <Input
                  size="sm"
                  placeholder="Salary (e.g. Rp 4.500.000 = 4500000"
                  type="number"
                  id="salary"
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              </FormControl>
            </SimpleGrid>

            <FormControl isRequired mt={5}>
              <FormLabel>Deskripsi</FormLabel>
              <Textarea
                placeholder="Jelaskan Jobdesk"
                id="description"
                onChange={(e) => handleExperienceChange(index, e)}
              />
            </FormControl>
          </Box>
        ))}
        <Divider borderWidth={2} borderColor="blackAlpha.800" my={2} />
        <VStack>
          <Heading>Pendidikan</Heading>
          <Text>Isi informasi pendidikan di bawah ini</Text>
        </VStack>
        <Button bg="brand.100" onClick={() => handleAddForms("education")}>
          Tambah Riwayat Pendidikan +
        </Button>

        {educationList.map((education, index) => (
          <Box key={index} w="full" p={2} borderRadius="md" borderWidth={2}>
            <FormControl isRequired mt={5}>
              <FormLabel>Universitas / Institusi</FormLabel>
              <Input
                size="sm"
                placeholder="Universitas"
                id="school"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Tanggal Wisuda</FormLabel>
              <Input
                size="sm"
                placeholder="Tanggal Wisuda"
                id="graduate"
                type="date"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>
            <FormControl isRequired mt={5}>
              <FormLabel>Level</FormLabel>
              <Select
                size="sm"
                id="level"
                onChange={(e) => handleEducationChange(index, e)}
              >
                <option value="">Pilih Level</option>
                <option value="S3">Doktor (S3)</option>
                <option value="S2">Magister (S2)</option>
                <option value="S1/D4">Sarjana / Diploma 4 (S1 / D4)</option>
                <option value="D3">Diploma 3 (D3)</option>
              </Select>
            </FormControl>
            <FormControl isRequired mt={5}>
              <FormLabel>Negara</FormLabel>
              <Input
                size="sm"
                placeholder="Negara (e.g Indonesia)"
                id="country"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>
            <FormControl isRequired mt={5}>
              <FormLabel>Program Studi</FormLabel>
              <Input
                size="sm"
                placeholder="Teknik Industri, etc"
                id="major"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>
            <FormControl isRequired mt={5}>
              <FormLabel>Nilai Akhir (IPK/GPA)</FormLabel>
              <Input
                size="sm"
                placeholder="3.00"
                id="gpa"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>

            <FormControl isRequired mt={5}>
              <FormLabel>Informasi tambahan</FormLabel>
              <Textarea
                id="information"
                onChange={(e) => handleEducationChange(index, e)}
              />
            </FormControl>
          </Box>
        ))}

        <HStack spacing={5}>
          <Button
            disabled={loading ? true : false}
            onClick={() => handleSave()}
          >
            Save {loading ? <Spinner mx={5} /> : null}
          </Button>
        </HStack>
      </Stack>
    </>
  );
};

export default AppBarEditEmployeeProfile;
