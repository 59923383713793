import { HStack, Spinner } from "@chakra-ui/react";
import React from "react";
import { useAppContext } from "../Context/AppContext";

export default function () {
  const { loading } = useAppContext();

  return (
    loading && (
      <HStack
        space={2}
        justifyContent="center"
        position="absolute"
        zIndex="1"
        height="full"
        width="100%"
        bg="rgba(0, 0, 0, 0.4)"
      >
        <Spinner accessibilityLabel="Loading..." color="white" />
      </HStack>
    )
  );
}
