import { Box, Button, FormControl, FormHelperText, FormLabel, Heading, Input, Spinner, Stack, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import Swal from 'sweetalert2';
import moment from 'moment';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import { useNavigate } from 'react-router-dom';

const AppBarThankyouBox = () => {
	const [to, setTo] = useState("");
	const [from, setFrom] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState("");
	const navigate = useNavigate();


	const handleSubmit = async () => {
		setLoading(true)
		if (!to){
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Kamu belum mengisi tujuan thankyou mu',
			})
			setLoading(false)
		} else if (to && !message) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Kamu belum mengisi message thankyou mu',
			})
			setLoading(false)
		} else if (to && message) {
			try {
				const docRef = await addDoc(collection(db, "thankyou_box"), {
					to: to,
					from: from,
					message : message,
					createdAt : moment().format(),
					timestamp : serverTimestamp()
				});
				console.log("Document written with ID: ", docRef.id);
				setLoading(false);
				Swal.fire({
					title: 'Message submitted successfully 👍🔥🫡🚀',
					showDenyButton: true,
					showCancelButton: false,
					confirmButtonText: 'Tulis Thankyou Message lagi',
					denyButtonText: `Selesai`,
				}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						window.location.reload()
						setLoading(false)
					} else if (result.isDenied) {
						Swal.fire('Redirecting...', '', 'info')
						navigate('/')
						setLoading(false)
					}
				})
			} catch (error) {
				Swal.fire({
					title: 'Maaf, terjadi kesalahan',
					showDenyButton: false,
					showCancelButton: false,
					confirmButtonText: 'Coba lagi',
					denyButtonText: `Don't save`,
				}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						Swal.fire('Saved!', '', 'success')
						window.location.reload();
						setLoading(false)
					} else if (result.isDenied) {
						Swal.fire('Changes are not saved', '', 'info')
						setLoading(false)
					}
				})
				setLoading(false)
			}
		};
	};


	return (
		<>
			<Stack display="flex" flexDir="row" justifyContent="center" alignItems="center" bg="gray.300" height={window.innerHeight * 1.1}>
				<Box bg='gray.50' boxShadow='xl' borderRadius='xl' w="70%" padding={5}> 
					<Heading textAlign='center'>Thankyou box</Heading>
					<Box display='flex' flexDirection='column' >
						<FormControl mb={5}>
							<FormLabel>From : </FormLabel>
							<Input borderRadius={20} boxShadow="md" bg='white' placeholder="John Doe (optional)" size='sm' onChange={e => setFrom(e.target.value)} />
						</FormControl>
						<FormControl mb={5}isRequired>
							<FormLabel>To : </FormLabel>
							<Input borderRadius={20} boxShadow="md" bg='white' placeholder="Jane Doe (required)" size='sm'  onChange={e => setTo(e.target.value)} />
						</FormControl>
						<FormControl mb={5}isRequired>
							<FormLabel isRequired>Message : </FormLabel>
							<Textarea  borderRadius={20} boxShadow="md" placeholder='Type your message here (required)' bg="white" resize='none' size='sm'  onChange={e => setMessage(e.target.value)} />
						</FormControl>
						<Button boxShadow='xl' colorScheme='green' color='white' m={4} onClick={handleSubmit}>Submit { loading?<Spinner mx={5} /> : null }</Button>
					</Box>
				</Box>
			</Stack>
		</>
	)
}

export default AppBarThankyouBox
