import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { RiWhatsappLine, RiWhatsappFill } from "react-icons/ri";

const CandidateCard = (props) => {
  const { handleMove, x, navigate } = props;
  return (
    <Box bgColor="white" borderRadius="md" m="1">
      <HStack>
        <Box>
          <Checkbox p="2" />
        </Box>
        <Box width="full" p="2">
          <HStack>
            <Text fontSize={12}>{x?.data?.name} </Text>
            <Text fontSize={10} color="gray.600">
              Applied on:{" "}
             <strong>{`${moment.unix(x?.data?.timestamp?.seconds).format("llll")}`}</strong> 
            </Text>
          </HStack>

          <Text fontSize={12}>
            {x?.data?.major} - {x?.data?.school}
          </Text>
          <Flex>
            <Text fontSize={12}>Status:</Text>
            <Badge  
              size='sm' 
              colorScheme={x?.data?.status == 'unprocessed' ? "red" :x?.data?.status == "not_suitable" ? "blackAlpha" : "green"} 
              mx={2}
            >
              {x?.data?.status}
            </Badge>
          </Flex>
          <Spacer />
        </Box>
        <Box p="2" borderLeft="1px">
          <Text fontSize={12}>Kontak</Text>
          <HStack>
            <RiWhatsappFill
              size="15"
              color="green"
              onClick={() => window.open(`https://wa.me/${x?.data?.phone}`)}
              cursor="pointer"
            />
            <RiWhatsappLine
              size="15"
              color="orange"
              onClick={() => window.open(`https://wa.me/${x?.data?.phone}`)}
              cursor="pointer"
            />
            <RiWhatsappLine
              size="15"
              color="orange"
              onClick={() => window.open(`https://wa.me/${x?.data?.phone}`)}
              cursor="pointer"
            />
            <RiWhatsappLine
              size="15"
              color="orange"
              onClick={() => window.open(`https://wa.me/${x?.data?.phone}`)}
              cursor="pointer"
            />
          </HStack>
        </Box>
      </HStack>
      <Divider />
      <Center p="1">
        <HStack >
          <Text fontSize={10}
          fontWeight='bold' cursor="pointer" onClick={() => navigate(`candidate/${x.id}`)}>
            Lihat
          </Text>
          <Text fontSize={10}
          fontWeight='bold'
            color="yellow.600"
            cursor="pointer"
            onClick={() => window.open(x?.data?.cv)}
          >
            Tinjau CV
          </Text>
          <Text fontSize={10}
          fontWeight='bold'
            color="green"
            cursor="pointer"
            onClick={() => handleMove("onboarding", x)}
          >
            Terpilih
          </Text>
          <Text fontSize={10}
          fontWeight='bold'
            color="red"
            cursor="pointer"
            onClick={() => handleMove("not_suitable", x)}
          >
            Tidak Sesuai
          </Text>
          <Spacer />
          <Select
          size="md"
          fontSize={10}
          fontWeight='bold'
            placeholder="Pindahkan"
            maxW="32"
            onChange={(e) => handleMove(e.target.value, x)}
          >
            <option value="unprocessed">Belum diproses</option>
            <option value="review">Tinjauan awal</option>
            <option value="interview">Wawancara</option>
            <option value="assessment">Asessment</option>
            <option value="onboarding">on Boarding</option>
            <option value="training">Training</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Select>
        </HStack>
      </Center>
    </Box>
  );
};

export default CandidateCard;
