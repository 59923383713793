import {
  Box,
  Stack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Button,
  Heading,
  Square,
  Textarea,
  Icon,
  SimpleGrid,
  Center,
  VStack,
  Input,
  HStack,
  Select,
  Divider,
  FormControl,
  Avatar,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  Tooltip,
  useColorModeValue,
  Flex,
  Image,
  Spinner,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  arrayUnion,
  where,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { useEffect } from "react";

import AvatarEditor from "react-avatar-editor";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { UploadBlob, UploadFile } from "../../Utils/Upload";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
// import { useAuthState } from "../../../Context";

function AppBarEditCompany() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [company, setCompany] = useState({});
  const [image, setImage] = useState("");
  const { userData } = useAppContext();

  const toast = useToast();
  let navigate = useNavigate();

  const handleData = (key, value) => {
    let newState = data;
    newState[key] = value;
    console.log(newState);
    setData(newState);
  };

  const getCompanyDetails = async () => {
    try {
      const docRef = doc(db, "company", userData.company);
      const docSnap = await getDoc(docRef);

      if (docSnap) {
        setCompany(docSnap.data());
        console.log(docSnap.data(), "data company");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const handleSaveImage = async () => {
    //handleSaveImage only saves image to storage, but the
    // url still needs to be posted to firebase firestore
    setLoading(true);
    try {
      const uploaded = await UploadBlob(
        image,
        userData.company,
        "avatar",
        "company profile"
      );
      if (uploaded) {
        setPreview(uploaded.url);
        console.log("PREVIEW", uploaded.url);
      }
    } catch (error) {
      console.log(error.message, "error on uploading image");
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    // console.log(data);
    if (preview) {
      try {
        const docSnap = await setDoc(
          doc(db, "company", userData.company),
          {
            ...data,
            image: preview,
          },
          { merge: true }
        );
        toast({
          title: "Update profil Perusahaan berhasil",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/employer/profile");
      } catch (error) {
        toast({
          title: "Terjadi Kesalahan",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else if (!preview) {
      try {
        const docSnap = await setDoc(
          doc(db, "company", userData.company),
          data,
          {
            merge: true,
          }
        );
        toast({
          title: "Update profil Perusahaan berhasil",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/employer/profile");
      } catch (error) {
        toast({
          title: "Update profil Perusahaan berhasil",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Stack spacing={{ base: "4", lg: "4" }} mt="10">
        <Stack>
          <Heading>Company Details</Heading>
          <Box>
            <Box mt={5} mb={5} p={3}>
              <Box mx={10}>
                <Heading size={"md"}>Company Information</Heading>
                <Text color="text-secondary" mt={2}>
                  Fill Input With Company Details
                </Text>
              </Box>
              <Flex spacing={10} m={10}>
                <Box
                  // w="200px"
                  // h="100px"
                  backgroundColor="gray.100"
                  borderColor="gray.200"
                  mx={10}
                  position="relative"
                >
                  <Image src={preview ? preview : company.image} maxW="10rem" />
                </Box>
                <Input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                  maxW="md"
                  mx={10}
                />
                {image ? (
                  <Button onClick={() => handleSaveImage()}>
                    Save{loading ? <Spinner mx={5} /> : null}
                  </Button>
                ) : null}
              </Flex>

              <Stack spacing={5} my={5} mx={10}>
                <Box>
                  <Heading size="sm">Tentang perusahaan</Heading>
                  <Textarea
                    placeholder={company.bio || "Bio"}
                    id="bio"
                    h="xs"
                    onChange={(e) => handleData(e.target.id, e.target.value)}
                  />
                </Box>
                <Box>
                  <Heading size="sm">Visi</Heading>
                  <Textarea
                    placeholder={company.vision || "Visi"}
                    id="vision"
                    onChange={(e) => handleData(e.target.id, e.target.value)}
                  />
                </Box>
                <Box>
                  <Heading size="sm">Misi</Heading>
                  <Textarea
                    placeholder={company.mission || "Misi"}
                    id="mission"
                    onChange={(e) => handleData(e.target.id, e.target.value)}
                  />
                </Box>
              </Stack>

              <SimpleGrid columns={2} spacing={2} m={5}>
                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">Nama Perusahaan</FormLabel>
                    <Input
                      placeholder={company.name || "PT Edrus Edukasi Utama"}
                      size="sm"
                      bgColor="blackAlpha.50"
                      id="name"
                      type="text"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">Alamat</FormLabel>
                    <Input
                      size="sm"
                      bgColor="blackAlpha.50"
                      id="alamat"
                      placeholder={
                        company.address ||
                        "Jl. Puri Utama no.7, Metland Puri, Cipondoh, Tangerang"
                      }
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="email">Email Perusahaan</FormLabel>
                    <Input
                      size="sm"
                      bgColor="blackAlpha.50"
                      id="email"
                      placeholder={company.email || "edrus@importir.co"}
                      type="email"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>

                {/* <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">Marital Status</FormLabel>
                    <Select
                      size="sm"
                      bgColor="blackAlpha.50"
                      placeholder="Select option"
                      id="maritalStatus"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    >
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                    </Select>
                  </FormControl>
                </Box> */}

                {/* <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">Gender</FormLabel>
                    <Select
                      size="sm"
                      bgColor="blackAlpha.50"
                      placeholder="Select option"
                      id="gender"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Select>
                  </FormControl>
                </Box> */}

                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="specific_location">
                      Specific Location
                    </FormLabel>
                    <Input
                      placeholder={
                        company.specificLocation || "E.g Cipondoh, Tangerang"
                      }
                      size="sm"
                      bgColor="blackAlpha.50"
                      id="specificLocation"
                      type="text"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">Industry</FormLabel>
                    <Input
                      size="sm"
                      bgColor="blackAlpha.50"
                      id={company.industry || "industry"}
                      type="text"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl px={5} isRequired>
                    <FormLabel htmlFor="karyawan">
                      Average Processing Time
                    </FormLabel>
                    <Input
                      size="sm"
                      bgColor="blackAlpha.50"
                      id="processingTime"
                      placeholder={
                        company.processingTime ||
                        "Processing time (e.g 14 days)"
                      }
                      type="text"
                      onChange={(e) => handleData(e.target.id, e.target.value)}
                    />
                  </FormControl>
                </Box>
              </SimpleGrid>
            </Box>
          </Box>

          <Button
            m={10}
            colorScheme="green"
            type="submit"
            onClick={() => handleSave()}
          >
            Save {loading ? <Spinner mx={5} /> : null}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default AppBarEditCompany;
