import { Box, Button, HStack, Spacer } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import AppBarHeaderMain from '../Components/AppBars/AppBarHeaderMain'

function MainEmployeePage() {
  return (
	<>
	{/* <AppBarHeaderMain/> */}
	<Box width='full' m='2' p='2' bgColor='gray.100'>
		<HStack p='1'>
			<Spacer/>
			<Link to="/employee/login">			
				<Button bgColor='brand.100'>Login</Button>
			</Link>
			<Button bgColor='brand.100'>Sign Up</Button>
		</HStack>
	</Box>
	</>
	)
}

export default MainEmployeePage