import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import { authFirebase, db, storage } from "../../Config/firebase";
import {
  ArrowLeftIcon,
  CalendarIcon,
  CheckIcon,
  InfoIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  List,
  ListIcon,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { UploadFile } from "../../Utils/Upload";
import Swal from "sweetalert2";
import { useAppContext } from "../../Context/AppContext";
import { generateRandomString } from "../../Utils/RandomString";

function JobDetailsPage() {
  const params = useParams();
  const [data, setData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [action, setAction] = useState("");
  const [upload, setUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [submitData, setSubmitData] = useState({
    name: authFirebase?.currentUser?.displayName || "null",
    email: authFirebase?.currentUser?.email || "null",
  });
  const [userData, setUserData] = useState({});
  const [percent, setPercent] = useState(0);
  let navigate = useNavigate();
  const toast = useToast();

  const formRef = useRef();

  const { availableRoles } = useAppContext();

  const x = availableRoles.filter((item) => item.id == params.id)[0];
  const dataContext = x ? x.data : {};
  const idContext = x ? x.id : {};

  console.log("dataContext", dataContext);

  const getUserData = () => {
    const x = localStorage.getItem("userData");
    // console.log("x: ", x);
    if (x) {
      setUserData(JSON.parse(x));
    }
  };

  const getJobDetails = async () => {
    try {
      const docRef = doc(db, "roles", params.id);
      const docSnap = await getDoc(docRef);
      if (docSnap) {
        setData(docSnap.data());
        setId(docSnap.id);
      }
      console.log(docSnap.data());
    } catch (error) {
      console.log(error.message, "error in getting job details");
    }
  };

  const getCompanyDetails = async () => {
    try {
      const docRef = doc(db, "company", data.companyId.toLowerCase());
      const docSnap = await getDoc(docRef);
      if (docSnap) setCompanyData(docSnap.data());
      // console.log("company", docSnap.data());
    } catch (error) {
      console.log(error.message, "error in getting company details");
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, [data]);

  const handleApply = () => {
    setAction("apply");
    if (authFirebase.currentUser == null) {
      localStorage.setItem("pendingLocation", window.location);
      navigate("/login");
    }
    formRef?.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    getJobDetails();
    getUserData();
  }, []);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitApplication = async () => {
    if (authFirebase.currentUser == null) {
      Swal.fire({
        title: "Not Logged In",
        text: "Silakan login terlebih dahulu untuk apply",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Login",
        denyButtonText: `Cancel`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Swal.fire("Saved!", "", "success");
          navigate("/employee/login");
        } else if (result.isDenied) {
          Swal.fire("Canceled", "", "info");
        }
      });
    } else if (authFirebase && !file) {
      Swal.fire({
        title: "Silakan Upload CV",
        text: "Upload CV terbaru untuk kami review ya!",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
        denyButtonText: `Cancel`,
      });
    } else {
      setLoading(true);
      setIsUploading(true);
      const uploadFile = await UploadFile(
        file,
        dataContext?.companyData?.name,
        userData.name
          ? userData.name + "_" + generateRandomString(6)
          : authFirebase.currentUser.displayName +
              "_" +
              generateRandomString(6),
        "cv"
      );
      // console.log("uploadFile", uploadFile);
      setPercent(uploadFile.percent);
      try {
        const addedData = await addDoc(
          collection(db, "roles", id, "candidates"),
          {
            ...submitData,
            userId: authFirebase.currentUser.uid,
            cv: uploadFile.url,
            status: "unprocessed",
            timestamp: serverTimestamp(),
            createdAt: Date.now().toString(),
          }
        );
        console.log("Document written with ID: ", addedData.id);
        const docRef = doc(db, "roles", params.id);
        await setDoc(
          docRef,
          { status_unprocessed: increment(1) },
          { merge: true }
        );
        setLoading(false);
        toast({
          title: "Apply Success",
          description: `Apply to ${data.position} success. Please wait for the recruiters to contact you.`,
          status: "success",
          isClosable: true,
        });
        navigate("/employee/dashboard");
      } catch (error) {
        setLoading(false);

        toast({
          title: "Something wrong happened...",
          description: error.message,
          status: "error",
          isClosable: true,
        });
      }
    }

    setLoading(false);
    setIsUploading(false);
  };

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        // onClick={() => navigate(`/employee/dashboard/${data.companyData.name}`)}
        variant="ghost"
      >
        {"<  "}Dashboard
      </Button>

      {/* --------------------------------------IKLANNN */}

      {action == "apply" ? null : (
        <>
          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <HStack>
              <Image boxSize="100px" src={data.companyData?.image} />
              <Stack>
                <Heading size="md">
                  {data?.position ? data.position : null}
                </Heading>
                <Heading size="sm" fontWeight={400} color="gray.500">
                  {data.companyId?.toUpperCase()}
                </Heading>
              </Stack>
            </HStack>
            <Box m="3">
              <Text>
                {companyData?.description ? companyData.description : null}
              </Text>
            </Box>
            <Box>
              <SimpleGrid columns="2">
                <List spacing={1}>
                  <ListItem>
                    <ListIcon as={InfoIcon} color="blue.500" />
                    {data.range_salary_high
                      ? `Rp${new Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(
                          data.range_salary_low
                        )} - Rp${new Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(data.range_salary_high)} `
                      : "Negotiable"}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={TimeIcon} color="blue.500" />
                    {data?.time ? data.time : <></>}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CalendarIcon} color="blue.500" />
                    {data?.experience ? data.experience : <></>}
                  </ListItem>
                </List>

                <List spacing={1}>
                  <ListItem>
                    <ListIcon as={InfoIcon} color="blue.500" />
                    {data?.job_location ? data.job_location : <></>}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={TimeIcon} color="blue.500" />
                    {/* {data?.time?data.time:<></>} */}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CalendarIcon} color="blue.500" />
                    {/* {data?.experience?data.experience:<></>} */}
                  </ListItem>
                </List>
              </SimpleGrid>
            </Box>
            <Divider m="2" bg="gray.700" my={8} />

            <Box>
              <Heading fontSize="lg">Job Description</Heading>
              <Text p="2">{data?.job_responsibility}</Text>

              <List spacing={1}>
                {data?.job_descriptions?.length > 0
                  ? data.job_descriptions.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                  : null}
              </List>
            </Box>
            <Box>
              <Divider m="2" bg="gray.700" my={8} />
              <Heading fontSize="lg">Criteria</Heading>
              <List spacing={1}>
                {data?.job_criteria?.length > 0
                  ? data.job_criteria.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                  : null}
              </List>
            </Box>
            <Divider bg="teal" my={10} />
            <Box>
              <Heading fontSize="lg">Company Profile</Heading>
              <Text mt={2}> {companyData ? companyData.bio : null}</Text>
              {companyData && companyData.vision ? (
                <>
                  <Heading fontSize="md">Vision</Heading>
                  <Text mt={2}> {companyData.vision}</Text>
                </>
              ) : null}

              {companyData && companyData.mission ? (
                <>
                  <Heading fontSize="md">Mission</Heading>
                  <Text mt={2}> {companyData.mission}</Text>
                </>
              ) : null}

              <HStack mt={5}>
                <Heading fontSize="md">Industry:</Heading>
                <Text mt={2}>
                  {companyData && companyData.industry
                    ? companyData.industry
                    : null}
                </Text>
              </HStack>
            </Box>
            <Center>
              <HStack p="2">
                <Button bgColor="brand.100" onClick={handleApply}>
                  Apply sekarang
                </Button>
                {/* <Button bgColor="brand.100">Simpan</Button>
            <Button bgColor="brand.100">Share</Button> */}
              </HStack>
            </Center>
          </Box>
        </>
      )}

      {/* --------------------------------------FORMS */}
      {authFirebase.currentUser == null ? (
        <Text color="red" fontSize={14}>
          *Kamu harus Login untuk mengisi form
        </Text>
      ) : null}

      {authFirebase.currentUser == null || action !== "apply" ? null : (
        <>
          <Box position={"relative"} ref={formRef}>
            <Container
              as={SimpleGrid}
              maxW={"7xl"}
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 10, lg: 32 }}
              py={{ base: 10, sm: 20, lg: 32 }}
            >
              <Stack spacing={{ base: 2, md: 20 }}>
                <Heading
                  lineHeight={1.1}
                  fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
                >
                  {data.position}
                </Heading>

                <Heading size="sm" color="gray.400">
                  {data.company}
                </Heading>
                <Text>{data.job_location}</Text>
              </Stack>
              <Stack
                bg={"gray.50"}
                rounded={"xl"}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: "lg" }}
              >
                <Stack spacing={4}>
                  <Heading
                    color={"gray.800"}
                    lineHeight={1.1}
                    fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                  >
                    Join our team
                    <Text
                      as={"span"}
                      bgGradient="linear(to-r, red.400,pink.400)"
                      bgClip="text"
                    >
                      !
                    </Text>
                  </Heading>
                  <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                    We’re looking for amazing talents just like you! Become a
                    part of our rockstar Intrapreneur team and skyrocket your
                    career!
                  </Text>
                </Stack>
                <Box as={"form"} mt={10}>
                  <Stack spacing={4}>
                    <Input
                      placeholder="Name (sesuai KTP)"
                      disabled={authFirebase.currentUser == null ? true : false}
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="name"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />
                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="Email (firstname@lastname.io)"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      defaultValue={authFirebase?.currentUser?.email}
                      id="email"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />
                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="No.HP 0812********"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="phone"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />

                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="Major (e.g S1 Teknik Industri)"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="major"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />
                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="Education (e.g Universitas Diponegoro)"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="school"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />
                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="Domicile (e.g Ciracas, Jakarta Timur)"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="domicile"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />
                    <Input
                      disabled={authFirebase.currentUser == null ? true : false}
                      placeholder="Salary expectation (e.g Rp 5.000.000 = 5000000)*"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.800"}
                      _placeholder={{
                        color: "gray.600",
                      }}
                      id="salary_expectation"
                      onChange={(e) =>
                        setSubmitData({
                          ...submitData,
                          [e.target.id]: e.target.value,
                        })
                      }
                    />

                    <Button
                      fontFamily={"heading"}
                      bg={"gray.200"}
                      color={"gray.800"}
                      onClick={() => setUpload(true)}
                    >
                      Upload CV
                    </Button>

                    {upload ? (
                      <Input
                        disabled={
                          authFirebase.currentUser == null ? true : false
                        }
                        placeholder="Choose file"
                        bg={"gray.100"}
                        border={0}
                        color={"gray.800"}
                        _placeholder={{
                          color: "gray.600",
                        }}
                        type="file"
                        onChange={handleFile}
                      />
                    ) : null}
                  </Stack>

                  <Button
                    fontFamily={"heading"}
                    mt={8}
                    w={"full"}
                    bgGradient="linear(to-r, yellow.400,pink.200)"
                    color={"white"}
                    _hover={{
                      bgGradient: "linear(to-r, #ffd600,yellow.400)",
                      boxShadow: "xl",
                    }}
                    onClick={() => handleSubmitApplication()}
                  >
                    Submit
                    {loading ? (
                      <>
                        <Spinner mx={5} />
                      </>
                    ) : null}
                  </Button>
                  {percent || isUploading ? (
                    <>
                      <Text>Uploading... {percent}%</Text>
                    </>
                  ) : null}
                </Box>
              </Stack>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}

export default JobDetailsPage;
