import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../Config/firebase";

const AppBarCandidateView = () => {
  const [candidates, setCandidates] = useState([]);
  const [candidateData, setCandidateData] = useState({});
  const [detailedCandidateData, setDetailedCandidateData] = useState({});
  const params = useParams();
  let navigate = useNavigate();
  const { id, candidateId } = params;

  const getCandidateData = async () => {
    const docRef = doc(db, "roles", id, "candidates", candidateId);

    try {
      const docSnap = await getDoc(docRef);
      setCandidateData(docSnap.data());
      console.log({ data: docSnap.data() });

      // if (docSnap.data().userId) {
      //   const docRef2 = doc(db, "users", candidateData.userId);
      //   const docSnap2 = await getDoc(docRef2);
      //   setDetailedCandidateData(docSnap2.data());
      // }
    } catch (error) {
      console.log(error.message, `error in getting ${candidateId} doc`);
    }
  };

  const getCandidateDetails = async () => {
    if (candidateData.userId) {
      const docRef2 = doc(db, "users", candidateData.userId);
      const docSnap2 = await getDoc(docRef2);
      setDetailedCandidateData(docSnap2.data());
    }
  };

  useEffect(() => {
    getCandidateData();
  }, []);

  useEffect(() => {
    getCandidateDetails();
  }, [candidateData.userId]);

  return (
    <>
      <Box position="absolute" margin={5}>
        <Button variant="ghost" onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
        </Button>
      </Box>
      <Box my={20} mx={{ base: 2, md: 3, lg: 10, xl: "10%" }}>
        {/* <Text>{JSON.stringify(candidateData)}</Text>
        <Text>{JSON.stringify(detailedCandidateData)}</Text> */}
        <Divider borderWidth={3} />
        <HStack>
          <Image src={detailedCandidateData.image} maxW="10rem" />
          <Stack>
            <Heading size="sm">{detailedCandidateData.name}</Heading>
            <Text color="blackAlpha.500">
              {candidateData.school}-{candidateData.major}
            </Text>
          </Stack>
        </HStack>
        <Divider mt={2} borderWidth={3} />
        <Heading size="md">Pengalaman</Heading>
        {detailedCandidateData && detailedCandidateData?.experiences?.length > 0
          ? detailedCandidateData?.experiences?.map((experience, index) => (
              <Box key={index} mt={5}>
                <Heading size="sm">{experience.position}</Heading>
                <Text fontWeight="bold" fontSize={14} color="blackAlpha.600">
                  {experience.company}
                </Text>
                <Text>Industri : {experience.industry}</Text>
                <Text>Spesialisasi : {experience.specialization}</Text>
                <Text>Level : {experience.level}</Text>
                <Text>Gaji Bulanan : IDR {experience.salary}</Text>
                <Text>
                  {experience.startDate}s.d.{experience.startDate}
                </Text>
                <Text fontSize={14} fontWeight="bold">
                  Deskripsi
                </Text>
                <Text>{experience.description}</Text>
              </Box>
            ))
          : null}
        <Divider mt={2} borderWidth={3} />
        <Heading size="md">Pendidikan</Heading>
        {detailedCandidateData && detailedCandidateData?.education?.length > 0
          ? detailedCandidateData?.education?.map((education, index) => (
              <Box key={index} mt={5}>
                <Heading size="sm">{education.school}</Heading>
                <Text fontWeight="bold" fontSize={14} color="blackAlpha.600">
                  {education.level}- {education.major}
                </Text>
                <Text>Nilai Akhir : {education.gpa}/4</Text>
                <Text>Tanggal Wisuda : {education.graduate}</Text>
                <Text>Negara : {education.country}</Text>

                <Text fontSize={14}>Informasi Tambahan</Text>
                <Text>{education.information}</Text>
              </Box>
            ))
          : null}
        <Divider borderWidth={3} />
      </Box>
    </>
  );
};

export default AppBarCandidateView;
