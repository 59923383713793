import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import AppBarHeader from "../../Components/AppBars/AppBarHeaderEmployer";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
import { useSearchParams } from 'react-router-dom';
import AppBarSideNavigation from '../../Components/AppBars/AppBarSideNavigation';

function DashboardEmployeePage() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { company } = params;
  let navigate = useNavigate();
  const { availableRoles } = useAppContext();
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
    xl: true,
  });

  const width = window.innerWidth;
  const height = window.innerHeight;



  const DesktopView = () => (
    <>
      <Stack>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 2 }}
          gap="2"
        >

        </SimpleGrid>
      </Stack>
    </>
  );

  const MobileView = () => (
    <>
      <Box pt={32}>
        {availableRoles
          .filter(
            (data) =>
              String(data.data.companyId).toLowerCase() ==
              String(company).toLowerCase()
          )
          .map((x, i) => (
            <Flex
              key={i}
              borderBottomColor="black.200"
              borderBottomWidth={5}
              // mt={2}
              shadow="md"
              // bg="teal"
              onClick={() => navigate(`/employee/jobs/${x.id}`)}
            >
              <Square size="100px" mx={2}>
                <Image src={x.data.companyData?.image} alt={x.data.company} />
              </Square>
              <Box flex="1" py={5} mx={1}>
                <Heading align="center" fontSize={14} fontWeight={400}>
                  {x.data.position}
                </Heading>
                <Text align="center" fontSize={11} color="gray.500">
                  {x.data.companyId.toUpperCase()}
                </Text>
                <Text align="center" fontSize={13} color="blackAlpha.500">
                  {x.data.job_location}
                </Text>
                <Text align="center" fontSize={13} color="blackAlpha.500">
                  {x.data.expiry}
                </Text>
              </Box>
            </Flex>
          ))}
      </Box>
    </>
  );


  const data = searchParams.get('kontol');

  return (
    <AppBarSideNavigation >
       <Box flex={1}>{isDesktop ? <DesktopView /> : <MobileView />}</Box>
    </ AppBarSideNavigation >
  );
}

export default DashboardEmployeePage;
