import React from 'react'
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation';
import AppBarMain from '../Components/AppBars/AppBarMain';

const WelcomePage = () => {
  return (
    <>
      <AppBarSideNavigation>
        <AppBarMain />
      </AppBarSideNavigation>
    </>
  )
}

export default WelcomePage
