import React from "react";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import AppBarHeader from "../../Components/AppBars/AppBarHeaderEmployer";
import AppBarHeaderMain from "../../Components/AppBars/AppBarHeaderMain";
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import AppBarProfile from "../../Components/AppBars/AppBarProfile";

const EmployerProfilePage = () => {
  return (
    <>
      {/* <AppBarHeaderNew /> */}
      <AppBarProfile />
    </>
  );
};

export default EmployerProfilePage;
