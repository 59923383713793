import React from 'react'
import AppBarJobsList from '../../Components/AppBars/AppBarJobsList'
import AppBarSideNavigation from '../../Components/AppBars/AppBarSideNavigation'

const JobsListPage = () => {
  return (
    <>
      <AppBarSideNavigation>
        <AppBarJobsList />
      </AppBarSideNavigation>
    </>
  )
}

export default JobsListPage
