import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import AppBarHeaderMain from "../Components/AppBars/AppBarHeaderMain";
import { AppBarHeaderNew } from "../Components/AppBars/AppBarHeaderNew";
import HeroHome from "../Components/AppComponents/HeroHome";
import AppBarSideNavigation from '../Components/AppBars/AppBarSideNavigation'

function MainPage() {
  return (
    <>
        {/* <AppBarSideNavigation> */}
          <HeroHome />
        {/* </AppBarSideNavigation> */}
    </>
  );
}

export default MainPage;
