import React from "react";
import AppBarSideNavigation from "../../../Components/AppBars/AppBarSideNavigation";
import AppBarTrainingAdmin from "../../../Components/AppBars/AppBarTrainingAdmin";

const AdminTrainingPage = () => {
  return (
      <AppBarSideNavigation>
        <AppBarTrainingAdmin />
      </AppBarSideNavigation>
  )
};

export default AdminTrainingPage;
