import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../Config/firebase";

export const UploadFile = async (file, company, name, type) => {
  console.log("inside upload");
  if (!file) {
    alert("Please upload an image first!");
  }

  const storageRef =
    type == "cv"
      ? ref(storage, `${company}/cv/${name}`)
      : ref(storage, `${company}/${type}/${name}`);

  // progress can be paused and resumed. It also exposes progress updates.

  // Receives the storage reference and the file to upload.

  return new Promise((resolve, reject) => {
    //   uploadBytes(storageRef, file)
    uploadBytesResumable(storageRef, file)
      .then((snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          resolve({
            percent: percent,
            url: downloadURL,
          });
          console.log("Uploaded file!");
        });
      })
      .catch((error) => reject(error.message));
  });
};
export const UploadBlob = async (file, folder, name, type) => {
  console.log("inside upload");
  if (!file) {
    alert("Please upload an image first!");
  }

  const storageRef = ref(storage, `${folder}/${type}/${name}`);

  // progress can be paused and resumed. It also exposes progress updates.

  // Receives the storage reference and the file to upload.

  return new Promise((resolve, reject) => {
    //   uploadBytes(storageRef, file)
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          resolve({
            percent: percent,
            url: downloadURL,
          });
          console.log("Uploaded file!");
        });
      })
      .catch((error) => reject(error.message));
  });
};
