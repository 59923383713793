import {
  Badge,
  Box,
  Button,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import Image from "../../Image/Image.svg";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { authFirebase } from "../../Config/firebase";

function HeroHome() {
  const { currentUser } = authFirebase;

  const navigate = useNavigate();
  return (
    <Box
      position="relative"
      height={{
        base: "800px",
        lg: "750px",
      }}
      bg="white"
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={"10"}>
        <Stack mt={{ base: "10px", lg: "145px" }}>
          <Img boxSize="full" objectFit="cover" src={Image} alt="Projeq.id" />
        </Stack>
        <Stack
          // alignItems="center"
          alignSelf={"center"}
          mt={{ base: "10px", lg: "145px" }}
          spacing={{
            base: "8",
            md: "12",
          }}
          justifyItems="end"
          right={0}
        >
          <Stack spacing="4" alignContent={"end"} p={5}>
            <Badge
              colorScheme="yellow"
              alignSelf="start"
              size={useBreakpointValue({
                base: "md",
                md: "lg",
              })}
            >
              Welcome to
            </Badge>
            <Stack
              spacing={{
                base: "4",
                md: "6",
              }}
              maxW={{
                md: "xl",
                lg: "md",
                xl: "xl",
              }}
            >
              <Heading
                size={useBreakpointValue({
                  base: "md",
                  md: "xl",
                })}
              >
                INTRAPRENEUR
              </Heading>
              <Text
                fontSize={{
                  base: "lg",
                  md: "xl",
                }}
                color="muted"
              >
                Search for the best talents for your company or dream jobs for
                your career
              </Text>
            </Stack>
          </Stack>
          <Stack
            direction={{
              base: "column",
              md: "row",
            }}
            spacing="3"
            p={5}
          >
            {currentUser ? (
              <Button
                bg="#ffd600"
                color="black"
                rounded="20px"
                py={2}
                px={7}
                onClick={() => (window.location.href = "/talent/dashboard")}
                size={"lg"}
                width={"40%"}
                shadow={"dark-lg"}
              >
                Go to Dashboard
              </Button>
            ) : (
              <>
                <Button
                  bg="#ffd600"
                  color="black"
                  rounded="20px"
                  py={2}
                  px={7}
                  onClick={() => navigate("login")}
                  size={"lg"}
                  width={"40%"}
                  shadow={"dark-lg"}
                >
                  Sign in
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}

export default HeroHome;
