import React from 'react'
import { Box, Avatar, Heading, Text, Progress } from "@chakra-ui/react";

const AppBarTeams = () => {
     const teamMembers = [
        {
        name: "John Doe",
        position: "Algotrading",
        avatarUrl: "https://randomuser.me/api/portraits/men/11.jpg",
        progress: 60,
        },
        {
        name: "Jane Smith",
        position: "Algotrading",
        avatarUrl: "https://randomuser.me/api/portraits/women/12.jpg",
        progress: 80,
        },
        // ... more team members
    ];


    const  TeamMemberCard = ({ avatarUrl, name, position, progress }) => {
    return (
        <Box
            maxW="xs"
            borderWidth="1px"
            borderRadius="lg"
            // overflow="hidden"
            bgColor='white'
            boxShadow='md'
            display='flex'
        >
            <Avatar size="lg" src={avatarUrl} m={2}/>
            <Box p="6">
                <Box d="flex" alignItems="baseline">
                <Heading as="h2" size="sm" mr="2">
                    {name}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                    {position}
                </Text>
                </Box>
                <Progress size='xs' mt="4" value={progress} />
            </Box>
        </Box>
    );
    }

  return (
    <>
       {teamMembers.map((member, index) => (
        <TeamMemberCard
          key={index}
          avatarUrl={member.avatarUrl}
          name={member.name}
          position={member.position}
          progress={member.progress}
        />
      ))}
    </>
  )
}

export default AppBarTeams
