import {
  Button,
  Box,
  Flex,
  Image,
  SimpleGrid,
  Square,
  Text,
  useBreakpointValue,
  Heading,
  Card,
  CardBody,
  Stack,
  CardFooter,
  Progress,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarHeaderEmployee from "../../Components/AppBars/AppBarHeaderEmployee";
import AppBarHeader from "../../Components/AppBars/AppBarHeaderEmployer";
import { db } from "../../Config/firebase";
import { useAppContext } from "../../Context/AppContext";
import AppBarSideNavigation from '../../Components/AppBars/AppBarSideNavigation';
import { AppBarHeaderNew } from "../../Components/AppBars/AppBarHeaderNew";
import youtubeThumbnail from 'youtube-thumbnail';

function MainDashboardPage() {
  const params = useParams();
  const { company } = params;
  let navigate = useNavigate();

  const [companies, setCompanies] = useState([]);
  const { availableRoles } = useAppContext();
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
    xl: true,
  });


  const courses = [
    {
      title : 'Content Creation',
      video : 'https://www.youtube.com/results?search_query=instacoachmike',
      progress: 80,
    },
    {
      title : 'Content Creation 2',
      video : 'https://www.youtube.com/watch?v=znlW0Klm5m0',
      progress: 80,
    },
    {
      title : 'Content Creation 3',
      video : 'https://www.youtube.com/watch?v=gkGx9kHdH6s',
      progress: 80,
    },
  ];



  const CardCourse = () => {
    return (
      <>
          <Card maxW='10em' bg='white' cursor='pointer' _hover={{ transform: "scale(1.)", transition: "60ms linear"}}>
            <CardBody padding={0} m={2}>
              <Image
                src='https://i3.ytimg.com/vi/znlW0Klm5m0/maxresdefault.jpg'
                alt='https://i3.ytimg.com/vi/znlW0Klm5m0/maxresdefault.jpg'
                w='100%'
              />
              <Stack mt='6' spacing='3'>
                <Heading size='xs'>Content Creation part 1</Heading>
              </Stack>
                <Progress hasStripe size='sm' value={80}/> 
            </CardBody>
            <CardFooter>
              <HStack>
            <Text fontSize={8}>10/15</Text>
              </HStack>
            </CardFooter>
          </Card>
      </>
    )
  };
  return (
    <>
    {/* <AppBarHeaderNew/> */}
      <AppBarSideNavigation>
            <Box
              justifyContent="center"
              alignItems="center"
              // height="100vh"
              // bg="gray.100"
            >
              <Heading size='xs' my={4}>Main Dashboard</Heading>
            
              <Flex flexDir='row' flexWrap='wrap' gap={3}>
                {courses
                  ? courses.map((company, index) => (
                      <CardCourse key={index} />
                    ))
                  : <Text>Tidak ada company</Text>}
              </Flex>
            </Box>
      </AppBarSideNavigation>

    </>
  );
}

export default MainDashboardPage;
