import {
  Avatar,
  Box,
  Center,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function AppBarHeader() {
  let navigate = useNavigate();
  return (
    <>
      <Box width="full" height="16" bgColor="#ffd600">
        <SimpleGrid columns="3">
          <Box></Box>
          <Center>
            <HStack>
              <Link to="/employer/dashboard">Dashboard</Link>
              <Link to="/employer/roles">Roles</Link>
              {/* <Link to="/employer/dashboard">Find Talents</Link> */}
              <Link to="/employer/talents">Talents</Link>
              <Link to="/employer/dashboard">Chat</Link>
              <Link to="/employer/dashboard">Report</Link>
            </HStack>
          </Center>
          <Box alignItems="end">
            <Avatar
              onClick={() => navigate("/employee/profile")}
              my={2}
              cursor="pointer"
            />
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
}

export default AppBarHeader;
