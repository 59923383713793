import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftIcon,
  CalendarIcon,
  CheckIcon,
  InfoIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  List,
  ListIcon,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Swal from "sweetalert2";
import { useAppContext } from "../../../Context/AppContext";

function RolesPreviewPage() {
  const params = useParams();
  const [data, setData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  let navigate = useNavigate();
  const toast = useToast();

  const formRef = useRef();

  const handleApply = () => {
    Swal.fire({
      title: "Kamu gak bisa apply, gan!",
      text: "You are the employer",
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Ok",
      denyButtonText: `Cancel`,
    });
  };

  const { availableRoles } = useAppContext();

  const x = availableRoles.filter((item) => item.id == params.id)[0];
  const dataContext = x ? x.data : {};
  const idContext = x ? x.id : {};

  return (
    <>
      {/* --------------------------------------IKLANNN */}

      {action == "apply" ? null : (
        <>
          <Box m="2" p="2" bg="gray.100" borderRadius="md">
            <HStack>
              <Image boxSize="100px" src={dataContext.companyData?.image} />
              <Stack>
                <Heading size="md">
                  {dataContext?.position ? dataContext.position : null}
                </Heading>
                <Heading size="sm" fontWeight={400} color="gray.500">
                  {dataContext.companyId?.toUpperCase()}
                </Heading>
              </Stack>
            </HStack>
            <Box m="3">
              <Text>
                {dataContext.companyData?.bio ? companyData.bio : null}
              </Text>
            </Box>
            <Box>
              <SimpleGrid columns="2">
                <List spacing={1}>
                  <ListItem>
                    <ListIcon as={InfoIcon} color="blue.500" />
                    {dataContext.range_salary_high
                      ? `Rp${new Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(
                          data.range_salary_low
                        )} - Rp${new Intl.NumberFormat("en-ID", {
                          maximumSignificantDigits: 3,
                        }).format(data.range_salary_high)} `
                      : "Negotiable"}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={TimeIcon} color="blue.500" />
                    {dataContext?.time ? dataContext.time : <></>}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CalendarIcon} color="blue.500" />
                    {dataContext?.experience ? dataContext.experience : <></>}
                  </ListItem>
                </List>

                <List spacing={1}>
                  <ListItem>
                    <ListIcon as={InfoIcon} color="blue.500" />
                    {dataContext?.job_location ? (
                      dataContext.job_location
                    ) : (
                      <></>
                    )}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={TimeIcon} color="blue.500" />
                    {/* {data?.time?data.time:<></>} */}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CalendarIcon} color="blue.500" />
                    {/* {data?.experience?data.experience:<></>} */}
                  </ListItem>
                </List>
              </SimpleGrid>
            </Box>
            <Divider m="2" bg="gray.700" my={8} />

            <Box>
              <Heading fontSize="lg">Job Description</Heading>
              <Text p="2">{dataContext?.job_responsibility}</Text>

              <List spacing={1}>
                {dataContext?.job_descriptions?.length > 0
                  ? dataContext.job_descriptions.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                  : null}
              </List>
            </Box>
            <Box>
              <Divider m="2" bg="gray.700" my={8} />
              <Heading fontSize="lg">Criteria</Heading>
              <List spacing={1}>
                {dataContext?.job_criteria?.length > 0
                  ? dataContext.job_criteria.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                  : null}
              </List>
            </Box>
            <Divider bg="teal" my={10} />
            <Box>
              <Heading fontSize="lg">Company Profile</Heading>
              <Text mt={2}>
                {" "}
                {dataContext.companyData ? dataContext.companyData.bio : null}
              </Text>
              {dataContext.companyData && dataContext.companyData.vision ? (
                <>
                  <Heading fontSize="md">Vision</Heading>
                  <Text mt={2}> {dataContext.companyData.vision}</Text>
                </>
              ) : null}

              {dataContext.companyData && dataContext.companyData.mission ? (
                <>
                  <Heading fontSize="md">Mission</Heading>
                  <Text mt={2}> {companyData.mission}</Text>
                </>
              ) : null}

              <HStack mt={5}>
                <Heading fontSize="md">Industry:</Heading>
                <Text mt={2}>
                  {dataContext.companyData && dataContext.companyData.industry
                    ? dataContext.companyData.industry
                    : null}
                </Text>
              </HStack>
            </Box>
            <Center>
              <HStack p="2">
                <Button bgColor="brand.100" onClick={handleApply}>
                  Apply sekarang
                </Button>
                {/* <Button bgColor="brand.100">Simpan</Button>
                <Button bgColor="brand.100">Share</Button> */}
              </HStack>
            </Center>
          </Box>
        </>
      )}
    </>
  );
}

export default RolesPreviewPage;
